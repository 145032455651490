import {Vue} from 'vue-property-decorator';
import {LeagueId} from '@/model/core/core-data.model';
import {HttpResponse} from 'vue-resource/types/vue_resource';
import {IndexedTeam} from '@/model/site/state.model';
import { BASE_URL } from './base.service';
const teamData = require('@/assets/static/teams.json');

const TEAM_BASE_URL = `${BASE_URL}/teams`;

const TEAM_SERVICE = {
	teams: async function (seasonId: string, leagueId: LeagueId): Promise<IndexedTeam> {
		const teams: any = teamData;
		if (teams[seasonId] && teams[seasonId][leagueId]) {
			return Promise.resolve(teams[seasonId][leagueId]);
		}
		Vue.$log.warn(`No teams found in ${seasonId} for league ${leagueId}, using API as fallback!`);
		return Vue.http.get(`${TEAM_BASE_URL}/season/${seasonId}/league/${leagueId}`, {}).then((r: HttpResponse) => r.data);
	},
	teamById: function (teamId: string, seasonId: string, leagueId?: LeagueId | LeagueId[]): Promise<any> {
		const teams: any = teamData;
		if (leagueId) {
			const leagueIds = Array.isArray(leagueId) ? leagueId : [leagueId];
			let leagueIdx: any;
			// leagueIds = ['a', 'b'];
			for (leagueIdx in leagueIds) {
				if (teams[seasonId] && teams[seasonId][leagueIds[leagueIdx]] && teams[seasonId][leagueIds[leagueIdx]][teamId]) {
					return Promise.resolve({data: teams[seasonId][leagueIds[leagueIdx]][teamId]});
				}
			}
		}
		Vue.$log.warn(`Team ${teamId} in ${seasonId} for league ${leagueId} not found, using API as fallback!`);
		return Vue.http.get(
				`${TEAM_BASE_URL}/${teamId}`, {
					params: {
						seasonId: seasonId,
					},
				});
	},
};

export default TEAM_SERVICE;
