import dayjs from 'dayjs';

// ---- date constants
export const TEMPORAL_NULL = new Date(1970, 0, 1);
export const TEMPORAL_INFINITY = new Date(3000, 11, 31);

// ---- date formats
export const RANKING_DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_ISO = 'YYYY-MM-DD';

// ---- utility functions
export function isAnotherDay(date1: Date, date2?: Date): boolean {
	if (!date2) {
		return true;
	}
	return !isSameDay(date1, date2);
}

export function isSameDay(date1: Date, date2: Date): boolean {
	return dayjs(date1).startOf('day').isSame(dayjs(date2).startOf('day'));
}
