












import {Component, Inject, Vue} from 'vue-property-decorator';
import {Settings} from '@/model/site/settings.model';

@Component({
  components: {},
})
export default class BreakpointInfo extends Vue {

  @Inject('settings') settings!: Settings;

  get width(): string {
    return `${this.settings.innerWidth} px`;
  }

}
