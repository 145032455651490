





























import {Component, Vue} from 'vue-property-decorator';
import COMMON_UTIL from '@/utils/common.util';

@Component({
  components: {},
})
export default class DevelLink extends Vue {

  get links(): string[] {
    const path = this.$route.fullPath;
    return COMMON_UTIL.links.develLinks(path);
  }

}
