import {LeagueId, sortLeagueIds, sortGroupIds} from '@/model/core/core-data.model';
import dayjs from 'dayjs';
import {extractGroupIds, extractLeagueIds} from '@/store/data/index';
import {GameInfo, IndexedGames, sortGamesPicturesFirst} from '@/model/game/game.model';
import {Vue} from 'vue-property-decorator';

const union = require('lodash/union');

export const LEAGUE_IDS = 'leagueIds';
export const GROUP_IDS = 'groupIds';
export const TOPGAME = 'topgame';
export const LASTGAMES = 'lastgames';
export const NEXTGAMES = 'nextgames';
export const GAMES_AT_DATE = 'gamesAtDate';
export const GAMES = 'games';
export const CURRENT_GAME_ID = 'currentGameId';
export const RANKING = 'ranking';
export const PLAYOFF = 'playoff';
export const HEADLINES_AT_DATE = 'headlinesAtDate';
export const HEADLINES_DATE = 'headlinesDate';
export const LATEST_HEADLINES = 'latestHeadlines';
export const PLAYER_NEWS = 'playerNews';
export const TEAM_NEWS = 'teamNews';
export const OTHER_NEWS = 'otherNews';

const GETTERS = {
	[LEAGUE_IDS]: (data: any) => {
		const leagueIds = sortLeagueIds(extractLeagueIds(data.gamesAtDate));
		if (leagueIds.length > 0) {
			return leagueIds;
		}
		return sortLeagueIds(union(extractLeagueIds(data.lastgames), extractLeagueIds(data.nextgames)));
	},
	[GROUP_IDS]: (data: any) => {
		const groupIds = extractGroupIds(data.gamesAtDate);
		if (Object.keys(groupIds).length > 0) {
			return groupIds;
		}
		return extractGroupIds(union(data.gamesAtDate, data.lastgames, data.nextgames));
	},
	[TOPGAME]: (data: any) => data.topgame,
	[LASTGAMES]: (data: any) => data.lastgames,
	[GAMES]: (data: any) => data.gamesCache,
	[NEXTGAMES]: (data: any) => data.nextgames,
	[GAMES_AT_DATE]: (data: any) => data.gamesAtDate,
	[CURRENT_GAME_ID]: (data: any) => data.currentGameId,
	[RANKING]: (data: any) => data.ranking,
	[PLAYOFF]: (data: any) => data.playoff,
	[PLAYER_NEWS]: (data: any) => data.playerNews,
	[TEAM_NEWS]: (data: any) => data.teamNews,
	[OTHER_NEWS]: (data: any) => data.otherNews,
	[LATEST_HEADLINES]: (data: any) => data.latestHeadlines,
	[HEADLINES_AT_DATE]: (data: any) => data.headlinesAtDate,
	[HEADLINES_DATE]: (data: any) => {
		if (data.headlinesAtDate && data.headlinesAtDate.length > 0 && data.headlinesAtDate[0].timestamp) {
			return dayjs(new Date(data.headlinesAtDate[0].timestamp)).startOf('day').toDate();
		}
		return undefined;
	},
};

export function gamesByDate(gamesInfo: IndexedGames, leagueId: LeagueId, limitConfig?: any): IndexedGames {
	limitConfig = limitConfig ? limitConfig[leagueId] || limitConfig : undefined;
	const maxDays = limitConfig && limitConfig.days;
	const gameCntLimit = limitConfig && limitConfig.gameCntLimit;
	const result: GameInfo[] = [];
	if (gamesInfo && gamesInfo.hasOwnProperty(leagueId)) {
		const games = gamesInfo[leagueId];
		result.push(...games);
	}
	const dates = new Set();
	let currentDate: any = undefined;
	let gameCnt = 0;
	const games: IndexedGames = {};
	result.forEach(game => {
		const date = dayjs(game.timestamp * 1000).format('D. MMMM YYYY');
		if (gameCntLimit && date !== currentDate && gameCnt >= gameCntLimit) {
			Vue.$log.debug(`${leagueId}: GameCntLimit of ${gameCntLimit} reached after ${gameCnt} games, totalGames=${result.length}`);
			return;
		}
		currentDate = date;
		dates.add(date);
		if (maxDays && dates.size > maxDays) {
			Vue.$log.debug(`${leagueId}: DayLimit of ${maxDays} reached after ${gameCnt} games and ${dates.size - 1} days, totalGames=${result.length}`);
			return;
		}
		if (!games || !games.hasOwnProperty(date)) {
			games[date] = [];
		}
		games[date].push(game);
		gameCnt++;
	});
	Object.keys(games).forEach(date => {
		sortGamesPicturesFirst(games[date]);
	});
	return games;
}

export default GETTERS;
