import {GameInfo} from '@/model/game/game.model';
import {EMPTY_GROUP_ID} from '@/store/context.store';
import {isNumeric} from '@/utils/common.util';
import {Vue} from 'vue-property-decorator';

export interface Season {
	seasonId: string;
	shortDescription: string;
	longDescription: string;
	display: string;
}
export type LeagueId = '' | 'a' | 'b' | 'chl'	| 'cup'	| 'n'	| 's'	| 'u20' | 'u18' | 'fra' | 'f18' | 't';
const VALID_LEAGUE_IDS: LeagueId[] = ['a', 'b', 'chl', 'cup', 'n', 's', 'u20', 'u18', 'fra', 'f18', 't'];

export function isNLAB(leagueId: LeagueId): boolean {
	return leagueId === 'a' || leagueId === 'b';
}

export function isExhibitionLeague(leagueId: LeagueId) {
	return leagueId === 't';
}

// ---- Groups

export const GROUP_RELEGATION = 'r';
export function isGroupStage(game: GameInfo) {
	return getGroupId(game) !== EMPTY_GROUP_ID;
}
export function getGroupId(game: GameInfo): string {
	const league: any = GROUPS[game.leagueId];
	if (league) {
		const gameType = league[game.gameTypeId];
		if (gameType) {
			const groupId = gameType.find((g: any) => g === game.round);
			if (groupId) {
				return groupId;
			}
		}
	}
	return EMPTY_GROUP_ID;
}
export function sortGroupIds(groupIds: string[]): string[] {
	return groupIds.sort((o1, o2) => o1.localeCompare(o2));
}
/** LeagueId.GameTypeId: [Round] */
const GROUPS: any = {
	chl: {
		g: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p']
	},
	s: {
		p: ['c', 't']
	},
	n: {
		o: ['a', 'b', 'c'],
		w: ['a', 'b', 'c', 'd', 'e', 'f']
	},
	u20: {
		w: ['a', 'b']
	},
	u18: {
		w: ['a', 'b', 'r']
	},
	fra: {
		w: ['a', 'b', 'r'],
		o: ['a', 'b']
	},
	f18: {
		w: ['a', 'b', 'r'],
	}
};

// ---- Final Rounds

export const EMPTY_FINAL_ROUND_ID = '';
export function getFinalRoundId(game: GameInfo): string {
	const finalRoundIds: any = getFinalRoundIds(game);
	const idx = finalRoundIds.indexOf(game.round);
	if (idx >= 0) {
		return finalRoundIds[idx];
	}
	return EMPTY_FINAL_ROUND_ID;
}

/** Order is relevant */
const DEFAULT_FINAL_ROUND_DEFINITIONS = [
	'p16', 'p9', 'p8', 'p7', 'p5',
	'r',
	'p4', 'p4_1', 'p4_2', 'p4_3', 'p4_4',
	'p2_1', 'p2_2', 'p2',
	'p3',
	'p1',
	'pb', 'pc', 'pl'
];

/** LeagueId.GameTypeId: [FinalRound] */
const FINAL_ROUNDS: any = {
	a: {
		p: DEFAULT_FINAL_ROUND_DEFINITIONS
	},
	b: {
		p: DEFAULT_FINAL_ROUND_DEFINITIONS
	},
	n: {
		w: DEFAULT_FINAL_ROUND_DEFINITIONS,
		o: DEFAULT_FINAL_ROUND_DEFINITIONS
	},
	u18: {
		w: DEFAULT_FINAL_ROUND_DEFINITIONS
	},
	u20: {
		w: DEFAULT_FINAL_ROUND_DEFINITIONS
	},
	fra: {
		w: DEFAULT_FINAL_ROUND_DEFINITIONS,
		o: DEFAULT_FINAL_ROUND_DEFINITIONS
	},
	chl: {
		p: DEFAULT_FINAL_ROUND_DEFINITIONS
	},
	cup: {
		p: DEFAULT_FINAL_ROUND_DEFINITIONS
	},
	s: {
		p: DEFAULT_FINAL_ROUND_DEFINITIONS
	}
};
function getFinalRoundIds(game: GameInfo): string[] {
	const league: any = FINAL_ROUNDS[game.leagueId];
	if (league) {
		const gameType = league[game.gameTypeId];
		if (gameType) {
			return gameType;
		}
	}
	return [];
}

export function sortRound(a: string, b: string): number {
	const finalRoundA = DEFAULT_FINAL_ROUND_DEFINITIONS.indexOf(a);
	const finalRoundB = DEFAULT_FINAL_ROUND_DEFINITIONS.indexOf(b);
	if (finalRoundA >= 0 && finalRoundB >= 0) {
		return finalRoundA - finalRoundB;
	}
	if (finalRoundA === -1 && finalRoundB >= 0) {
		return -1;
	}
	if (finalRoundA >= 0 && finalRoundB === -1) {
		return +1;
	}
	return a.localeCompare(b);
}

// ---- GameTypeIds
export const ANY_GAME_TYPE_ID = undefined;
export type GameTypeId = 'm' | 'p' | 'r' | 't' | 'l' | 'g';
export const GAME_TYPE_IDS: GameTypeId[] = ['m', 'p', 'r', 't', 'g'];

// ---- Events: eventShortcut -> {gameType, leagueId}
export const EVENTS: any = {
	'a-wm': {gameTypeId: 'w', leagueId: 'n'},
	'olympia': {gameTypeId: 'o', leagueId: 'n'},
	'u18-wm': {gameTypeId: 'w', leagueId: 'u18'},
	'u20-wm': {gameTypeId: 'w', leagueId: 'u20'},
	'spengler-cup': {gameTypeId: 'p', leagueId: 's'},
	'swiss-cup': {gameTypeId: 'p', leagueId: 'cup'},
	'chl': {gameTypeId: [], leagueId: 'chl'},
	'frauen-wm': {gameTypeId: 'w', leagueId: 'fra'},
	'olympia-frauen': {gameTypeId: 'o', leagueId: 'fra'},
	'national-league': {gameTypeId: ['m', 'p', 'r'], leagueId: 'a'},
	'swiss-league': {gameTypeId: ['m', 'p', 'r'], leagueId: 'b'},
	// national exhibition-games (national pre-season) have leagueId='t', but no game-type set
	'testspiele': {gameTypeId: [], leagueId: 't'},
};

/** The lower the value, the higher the priority. */
const LEAGUE_ID_PRIORITIES: any = {
	a: 10,
	b: 20,
	chl: 100,
	cup: 30,
	n: 5,
};
const LOWEST_PRIO = 9999;

export function validLeagueId(leagueId: any): LeagueId | undefined {
	const exists = VALID_LEAGUE_IDS.find((key: any) => key === leagueId) !== undefined;
	return exists ? leagueId as LeagueId : undefined;
}

export function sortLeagueIds(leagueIds: string[]): string[] {
	return leagueIds.sort((o1, o2) => {
		const prio1 = LEAGUE_ID_PRIORITIES[o1] || LOWEST_PRIO;
		const prio2 = LEAGUE_ID_PRIORITIES[o2] || LOWEST_PRIO;
		return prio1 - prio2;
	});
}

export function isPlayoff(gameInfo: GameInfo): boolean {
	return gameInfo && gameInfo.playoffSerie !== undefined && gameInfo.playoffSerie !== null;
}

export function isSuisseCup(leagueId: LeagueId) {
	return leagueId === 'cup';
}

export function isExhibition(gameInfo: GameInfo): boolean {
	if (!gameInfo) {
		return false;
	}
	return isExhibitionGame(gameInfo.gameTypeId) || isExhibitionPreseason(gameInfo.gameTypeId, gameInfo.leagueId);
}

export function isExhibitionGame(gameTypeId: GameTypeId) {
	return gameTypeId === 't';
}

export function isExhibitionPreseason(gameTypeId: GameTypeId | GameTypeId[], leagueId: LeagueId): boolean {
	return isExhibitionLeague(leagueId) &&
		(
			gameTypeId === undefined || (Array.isArray(gameTypeId) && gameTypeId.length === 0)
		);
}

export function isRegularRoundGame(gameInfo: GameInfo): boolean {
	return gameInfo && isNumeric(gameInfo.round);
}

export interface TeamId {
	teamId: string;
	display: string;
	countryId?: string;
}

export interface Team extends TeamId {
	seasonId: string;
	leagueId: string;
	shortName: string;
	longName: string;
	city: string;
	stadium: string;
	url: '';
}

const TEAM_ID_SUI = 'sui';

export function isSwitzerland(teamId: TeamId) {
	return teamId.countryId === TEAM_ID_SUI;
}

export function isRealTeam(teamId: TeamId): boolean {
	if (!teamId.teamId) {
		Vue.$log.warn('Invalid teamId=', teamId);
		return false;
	}
	return !isPlaceholderTeam(teamId);
}
export function isPlaceholderTeam(teamId: TeamId): boolean {
	if (!teamId.teamId) {
		Vue.$log.warn('Invalid teamId=', teamId);
		return false;
	}
	if (teamId.display.length <= 2) { // sv1;T2;2. Gruppe Torriani
		return true;
	}
	if (teamId.display.indexOf('/') >= 0) { // sc1;Ambrì/Ufa;Verlierer Ambrì/Ufa
		return true;
	}
	if (teamId.display.startsWith('Sieger')) { // con_j;Sieger Gruppe J;Sieger Gruppe J
		return true;
	}
	if (teamId.display.startsWith('Verlierer')) { // con_j;Verlierer Gruppe J;Verlierer Gruppe J
		return true;
	}
	if (teamId.display.startsWith('Club')) { // vf1;Club 1;Club 1
		return true;
	}
	return false;
}

export function toTeamId(teamId: string, name: string, countryId?: string): TeamId {
	let display = name;
	if (countryId && countryId !== TEAM_ID_SUI && teamId !== countryId) {
		display = `${name} (${countryId.toUpperCase()})`;
	}
	return {teamId, display, countryId};
}
