import Vue from 'vue';
import Component from 'vue-class-component';
import S from 'string';

@Component({
	filters: {
		uppercase: function (text: string) {
			if (text) {
				return text.toUpperCase();
			}
			return '';
		},
		concat: function (text: string, value: string) {
			return text ? `${text}${value}` : '';
		},
		join: function (text: string[], glue: any) {
			return text.filter((t: string) => t).join(glue);
		},
		truncate: function (text: string, limit: number) {
			return S(text).truncate(limit);
		}
	},

})
export default class StringFilters extends Vue {

}
