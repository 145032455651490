import Vue from 'vue';
import BaseService from '@/services/base.service';
import {HttpResponse} from 'vue-resource/types/vue_resource';
import {ContentContainer} from '@/model/site/content.model';

export class ContentService extends BaseService {

	public staticContent(staticId: string): Promise<ContentContainer> {
		const url = this.hfUrl(`content/static/${staticId}`);
		return Vue.http.get(url)
			.then((r: HttpResponse) => r.data)
			.catch((error: any) => {});
	}

}
