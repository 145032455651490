import Vue from 'vue';
import BaseService from '@/services/base.service';
import {HttpResponse} from 'vue-resource/types/vue_resource';
import {BackgroundArticle, Headline} from '@/model/article/article.model';


export class BackgroundArticleService extends BaseService {

	public headlines(count: number): Promise<Headline[]> {
		const url = this.hfUrl(`background/headlines/${count}`);
		return Vue.http.get(url)
			.then((r: HttpResponse) => {
				return r.data.map((d: any) => {
					return {
						...d,
						createdAt: new Date(d.timestamp * 1000),
					};
				});
			})
			.catch((error: any) => {
			});
	}

	public authorHeadlines(authorId: string, count: number): Promise<Headline[]> {
		const url = this.hfUrl(`background/author/${authorId}/authorlimit/${count}`);
		return Vue.http.get(url)
			.then((r: HttpResponse) => {
				return r.data.map((d: any) => {
					return {
						...d,
						createdAt: new Date(d.timestamp * 1000),
					};
				});
			})
			.catch((error: any) => {
			});
	}

	public latest(count: number): Promise<BackgroundArticle[]> {
		const url = this.hfUrl(`background/latest/${count}`);
		return Vue.http.get(url)
			.then((r: HttpResponse) => {
				return r.data.map((d: any) => {
					return {
						...d,
						createdAt: new Date(d.timestamp * 1000),
						updatedAt: new Date(d.createdAt * 1000)
					};
				});
			})
			.catch((error: any) => {
			});
	}

	public detail(id: number): Promise<BackgroundArticle> {
		const url = this.hfUrl(`background/detail/${id}`);
		return Vue.http.get(url)
			.then((r: HttpResponse) => {
				return {
					...r.data,
					createdAt: new Date(r.data.updatedAt * 1000),
					updatedAt: new Date(r.data.createdAt * 1000)
				};
			})
			.catch((error: any) => {
				if (error.status === 404) {
					return Promise.reject(`Dieser Bericht existiert nicht`);
				}
			});
	}
}
