
















import {Component, Vue} from 'vue-property-decorator';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class HfCopyright extends Vue {
  @Prop({required: true}) year!: string;
}
