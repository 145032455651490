import Vue from 'vue';
import Router, {Route, RouterMode} from 'vue-router';
import {TopstoryType} from '@/model/article/article.model';
import {GameContentType, PlayerContentType} from '@/model/site/components.model';
import {DISABLE_REFRESHABLE} from '@/store/context.store';
import store from '@/store/index.store';
import {refreshAds} from '@/utils/ad.util';

const NPROGESS: any = require('nprogress');

Vue.use(Router);

/** Declare only when needed. */
export const ROUTES = {
	STATS_PLAYER: 'route-stats-player',
	STATS_TEAM: 'stats-team',
	STATS_GAME: 'stats-game',
	ERROR_PAGE: 'error-page',
};

export function playerStatsLink(playerId: string) {
	return {name: ROUTES.STATS_PLAYER, params: {playerId, contentType: PlayerContentType.STATS}};
}

export const ROUTING_MODE: RouterMode = (process.env.NODE_ENV === 'production' ? 'history' : 'hash');

export const ROUTING_UTILS = {
	pathForTopstory: function (type: TopstoryType, id: number | string): string | undefined {
		switch (type) {
			case 'news':
				return `news/meldung/${id}`;
			case 'article':
				return `background/${id}`;
			case 'game':
				return `spiel/${GameContentType.BERICHT}/${id}`;
			default:
				return undefined;
		}
	},
	linkPrefix: function (): string {
		return (ROUTING_MODE === 'hash') ? '#/' : '';
	},
};

// keep in sync with SitemapWebpackPlugin in vue.config.js
const router: any = new Router(<any>{
	mode: ROUTING_MODE,
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			component: () => import(/* webpackChunkName: "hf-index" */ '@/pages/IndexPage.vue'),
		},
		{
			path: '/news',
			component: () => import(/* webpackChunkName: "hf-news" */ '@/pages/NewsPage.vue'),
		},
		{
			name: 'route-news-id',
			path: '/news/meldung/:id?',
			component: () => import(/* webpackChunkName: "hf-news" */ '@/pages/NewsPage.vue'),
			props: true,
		},
		{
			name: 'route-matchday',
			path: '/spieltag',
			component: () => import(/* webpackChunkName: "hf-matchday" */ '@/pages/MatchdayPage.vue'),
		},
		{
			name: 'goldbach',
			path: '/ads/goldbach/:title?',
			component: () => import(/* webpackChunkName: "hf-ads" */ '@/pages/AdPage.vue'),
			props: true,
		},
		{
			name: 'route-matchday-date',
			path: '/spieltag/datum/:dateStr',
			component: () => import(/* webpackChunkName: "hf-matchday" */ '@/pages/MatchdayPage.vue'),
			props: true,
		},
		{
			path: '/spieltag/datum/:year/:month/:day',
			component: () => import(/* webpackChunkName: "hf-matchday" */ '@/pages/MatchdayPage.vue'),
			redirect: (to: Route) => {
				const params = to.params;
				const dateStr = `${params.year}-${params.month}-${params.day}`;
				return `/spieltag/datum/${dateStr}`;
			},
		},
		{
			name: 'route-game',
			path: '/spiel/:contentType/:gameId',
			component: () => import(/* webpackChunkName: "hf-game" */ '@/pages/GamePage.vue'),
			props: true,
		},
		{
			path: '/liga/:eventShortcut/:seasonId?',
			component: () => import(/* webpackChunkName: "hf-tournament" */ '@/pages/TournamentPage.vue'),
			props: true,
		},
		{
			// the constant 'saison' part is necessary for two reasons, backward comptability and not too aggressive path-matching
			path: '/:eventShortcut/saison/:seasonId?',
			component: () => import(/* webpackChunkName: "hf-tournament" */ '@/pages/TournamentPage.vue'),
			props: true,
		},
		{
			name: 'route-tournament-season',
			path: '/turnier/:eventShortcut/:seasonId?',
			component: () => import(/* webpackChunkName: "hf-tournament" */ '@/pages/TournamentPage.vue'),
			props: true,
		},
		{
			name: 'route-background',
			path: '/hintergrund/',
			component: () => import(/* webpackChunkName: "hf-background" */ '@/pages/BackgroundPage.vue'),
		},
		{
			name: 'route-roster',
			path: '/roster/:teamId/:seasonId?',
			component: () => import(/* webpackChunkName: "hf-background" */ '@/pages/RosterPage.vue'),
		},
		{
			path: '/background/:id',
			redirect: '/hintergrund/artikel/:id'
		},
		{
			name: 'route-background-detail',
			path: '/hintergrund/artikel/:id',
			component: () => import(/* webpackChunkName: "hf-background" */ '@/pages/BackgroundDetailPage.vue'),
			props: true
		},
		{
			name: 'route-impressum',
			path: '/impressum',
			component: () => import(/* webpackChunkName: "hf-static" */ '@/pages/static/AboutUsPage.vue'),
		},
		{
			name: 'route-privacy-notice',
			path: '/datenschutz',
			component: () => import(/* webpackChunkName: "hf-static" */ '@/pages/static/PrivacyNoticePage.vue'),
		},
		{
			name: 'route-funding',
			path: '/sponsoring',
			component: () => import(/* webpackChunkName: "hf-static" */ '@/pages/static/FundingPage.vue'),
		},
		{
			path: '/layout',
			component: () => import(/* webpackChunkName: "hf-static" */ '@/pages/static/LayoutPage.vue'),
		},
		{
			name: 'route-stats-portal',
			path: '/stats',
			component: () => import(/* webpackChunkName: "hf-stats" */ '@/pages/stats/StatsPortalPage.vue'),
		},
		{
			path: '/stats/test',
			component: () => import(/* webpackChunkName: "hf-stats-test" */ '@/pages/stats/StatsTestPage.vue'),
		},
		{
			name: 'stats-portal-leagueId',
			path: '/stats/portal/:leagueId?',
			component: () => import(/* webpackChunkName: "hf-stats" */ '@/pages/stats/StatsPortalPage.vue'),
			props: true,
		},
		{
			path: '/stats/spieler',
			component: () => import(/* webpackChunkName: "hf-stats" */ '@/pages/stats/PlayerStatsPage.vue'),
		},
		{
			path: '/stats/goalie',
			component: () => import(/* webpackChunkName: "hf-stats" */ '@/pages/stats/GoalieStatsPage.vue'),
		},
		{
			path: '/stats/leader',
			component: () => import(/* webpackChunkName: "hf-stats" */ '@/pages/stats/LeaderStatsPage.vue'),
		},
		{
			name: ROUTES.STATS_TEAM,
			path: '/stats/team/:preselectedStatsQuery?',
			component: () => import(/* webpackChunkName: "hf-stats" */ '@/pages/stats/TeamStatsPage.vue'),
			props: true
		},
		{
			name: ROUTES.STATS_GAME,
			path: '/stats/game/:preselectedTeamId?',
			component: () => import(/* webpackChunkName: "hf-stats" */ '@/pages/stats/GameStatsPage.vue'),
			props: true
		},
		{
			name: ROUTES.STATS_PLAYER,
			path: '/spieler/:playerId/:contentType',
			component: () => import(/* webpackChunkName: "hf-stats" */ '@/pages/PlayerInfoPage.vue'),
			props: true
		},
		{
			path: '/forum',
			component: () => import(/* webpackChunkName: "hf-forum" */ '@/pages/ForumPage.vue'),
		},
		{
			path: '*',
			component: () => import(/* webpackChunkName: "hf-error" */ '@/pages/ErrorPage.vue'),
		},
		{
			path: '/404',
			component: () => import(/* webpackChunkName: "hf-error" */ '@/pages/ErrorPage.vue'),
		},
		{
			path: '/503',
			name: ROUTES.ERROR_PAGE,
			component: () => import(/* webpackChunkName: "hf-error" */ '@/pages/ServiceUnavailablePage.vue'),
		},
	],
	scrollBehavior(to: Route, from: Route, savedPosition: any) {
		if (savedPosition) {
			return savedPosition;
		} else if (to.hash) {
			return {selector: to.hash};
		} else {
			return {x: 0, y: 0};
		}
	},
});

router.beforeEach(function (to: Route, from: Route, next: any): void {
	try {
		NPROGESS.start();
		Vue.$ga.page(to.fullPath);
		Vue.$log.debug(`Routing to ${to.fullPath} [${from.fullPath}]`, to.name);
	} finally {
		next();
	}
});

router.afterEach((to: Route, from: Route) => {
	try {
		Vue.$log.debug(`Finished routing to ${to.fullPath}`);
		store.commit(DISABLE_REFRESHABLE);
		setTimeout(() => refreshAds(), 1000);
	} finally {
		NPROGESS.done();
	}
});

export default router;
