import Vue from 'vue';
import BaseService from '@/services/base.service';
import dayjs from 'dayjs';
import {Ranking, RankingId} from '@/model/ranking.model';
import {DATE_FORMAT_ISO} from '@/utils/date.util';


export class RankingService extends BaseService {

	public rankingAt(rankingId: RankingId): Promise<Ranking> {
		if (!rankingId.leagueId) {
			Vue.$log.warn(`No ranking loaded as no leagueId is given ${rankingId.key}`);
			return new Promise(() => {});
		}
		if (rankingId.date && !rankingId.seasonId) {
			const dateParam = dayjs(rankingId.date).format(DATE_FORMAT_ISO);
			const url = this.url(`ranking/${rankingId.leagueId}?date=${dateParam}`);
			return Vue.http.get(url).then((resp: any) => resp.data);
		} else {
			return this.ranking(rankingId);
		}
	}

	public ranking(rankingId: RankingId, group?: string): Promise<Ranking> {
		if (!rankingId.leagueId) {
			Vue.$log.warn(`No ranking loaded as no leagueId is given ${rankingId.key}`);
			return new Promise(() => {});
		}
		const params = new URLSearchParams();
		if (rankingId.seasonId) {
			params.append('seasonId', rankingId.seasonId);
		}
		if (rankingId.gameTypeId) {
			params.append('gameTypeId', rankingId.gameTypeId);
		}
		if (group) {
			params.append('group', group);
		}
		const url = this.url(`ranking/${rankingId.leagueId}?${params}`);
		return Vue.http.get(url).then((resp: any) => resp.data);
	}

}
