




















import {Component, Prop, Vue} from 'vue-property-decorator';
import COMMON_UTIL from '@/utils/common.util';
import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/browser';
import CountryFlag from '@/components/shared/CountryFlag.vue';
import {COUNTRY_SERVICE} from '@/services/country.service';

@Component({
  components: {CountryFlag}
})
export default class HfTeamLogo extends Vue {

  @Prop({required: true}) teamId!: string;
  @Prop({default: false}) tooltip?: string;
  @Prop() altText?: string;
  @Prop({default: true}) figureContainer?: boolean;
  @Prop({validator: HfTeamLogo.validSize}) size?: string;
  @Prop({type: Boolean, default: false}) forceModern?: boolean;

  static validSize(value: any): boolean {
    return ['regular', 'medium', 'small'].indexOf(value) !== -1;
  }

  get iso2Code(): string | undefined {
    return COUNTRY_SERVICE.hfToCountryIso2(this.teamId);
  }

  get sizeClass() {
    if (this.size === 'regular') {
      return 'is-128x128';
    }
    if (this.size === 'medium') {
      return 'is-96x96';
    }
    if (this.size === 'small') {
      return 'is-48x48';
    }
    return '';
  }

  get alt() {
    return this.altText || this.teamId || this.teamId.toUpperCase();
  }

  get logoPath() {
    if (this.forceModern) {
      return COMMON_UTIL.paths.modernLogoPath(this.teamId);
    }
    return COMMON_UTIL.paths.clubLogoPath(this.teamId);
    // return `img/logo/team/${this.teamId}.svg`;
  }

  get altPath() {
    return COMMON_UTIL.paths.clubLogoAltPath(this.teamId);
    // return `img/logo/legacy/${this.teamId}.png`;
  }

  get noLogo() {
    // this MUST exist
    return `img/no_logo.png`;
  }

  onError(error: any) {
    if (this.$browserDetect
      && this.$browserDetect.meta
      && this.$browserDetect.meta.ua
      && this.$browserDetect.meta.ua.indexOf('Prerender') >= 0) {
      return;
    }
    if (!error.path || !error.path[0]) {
      return;
    }
    const src = error.path[0].src;
    if (src.endsWith(this.altPath)) {
      if (src.endsWith(this.noLogo)) {
        Vue.$log.warn(`Fallback logo '${this.noLogo}' not found`, Severity.Error);
      } else {
        // fallback to logo-placeholder
        const message = `Logo for teamId=${this.teamId} not found, using fallback-logo '${this.noLogo}' instead`;
        Vue.$log.warn(message);
        Sentry.captureMessage(message, Severity.Warning);
        error.path[0].src = this.noLogo;
      }
    } else if (src.endsWith(this.noLogo)) {
      const message = `The no-logo '${this.noLogo}' was not found`;
      Sentry.captureMessage(message, Severity.Error);
      Vue.$log.error(message);
    } else {
      error.path[0].src = this.altPath;
    }
  }
}
