import Vue from 'vue';
import {LeagueId, Season} from '@/model/core/core-data.model';
import {zeroPad} from '@/utils/common.util';
import dayjs, {Dayjs} from 'dayjs';
import { BASE_URL } from './base.service';


const SEASON_BASE_URL = `${BASE_URL}/seasons`;

const SEASON_SERVICE = {
	all(leagueId?: LeagueId): Season[] {
		let stop = '0001';
		if (leagueId === 'a') {
			stop = '0001';
		} else if (leagueId === 'b') {
			stop = '0001';
		} else if (leagueId === 't') {
			stop = '0001';
		} else if (leagueId) {
			return [];
		}
		const seasons: Season[] = [];
		let i = 0;
		let date = dayjs();
		let currentId: string = this.seasonIdForDate(date);
		while (stop !== currentId) {
			i++;
			seasons.push(this.toSeason(currentId));
			date = date.add(-1, 'year');
			currentId = this.seasonIdForDate(date);
			if (i >= 100) {
				Vue.$log.warn('Too much seasons', i);
				break;
			}
		}
		return seasons;
	},
	current(): Season {
		const seasonId = this.currentId();
		return this.toSeason(seasonId);
	},
	currentId(): string {
		return this.seasonIdForDate(dayjs());
	},
	seasonEndDate(seasonId: string): Date {
		if (seasonId === this.current().seasonId) {
			return new Date();
		} else {
			const day = 1;
			const month = 6;
			const year = 2000 + Number.parseInt(seasonId.substr(2, 4));
			return new Date(year, month, day);
		}
	},
	seasonIdForDate(date: Dayjs): string {
		const year = date.year();
		const month = date.month();
		let startYear;
		let endYear;
		if (month >= 7) {
			// months 8-12 -> first part of season-id
			startYear = year;
			endYear = year + 1;
		} else {
			// months 1-7 -> second part of season-id
			startYear = year - 1;
			endYear = year;
		}
		// works only from year 2000 on
		startYear -= 2000;
		endYear -= 2000;
		return `${zeroPad(startYear, 2)}${zeroPad(endYear, 2)}`;
	},
	selection(seasonId: string): Promise<Selection> {
		return Vue.http.get(`${SEASON_BASE_URL}/gameCount/${seasonId}`);
	},
	seasonDesc(seasonId: string, long?: boolean, prefix?: string, postfix?: string): string {
		if (!seasonId || seasonId.length !== 4) {
			Vue.$log.warn(`Invalid season: ${seasonId}`);
			return '';
		}
		let start = Number.parseInt(seasonId.substring(0, 2));
		const end = Number.parseInt(seasonId.substring(2, 4));
		if (long) {
			const century = (start < 70) ? 2000 : 1900;
			start += century;
		}
		const startStr = zeroPad(start, 2);
		const endStr = zeroPad(end, 2);
		prefix = prefix || '';
		postfix = postfix || '';
		return `${prefix}${startStr}/${endStr}${postfix}`;
	},
	seasonsFallback(): string[] {
		return [
			'0102', '0203', '0304', '0405', '0506', '0607', '0708', '0809', '0910', '1011', '1112', '1213', '1314', '1415', '1516', '1617', '1718', '1819', '1920',
		].sort((a: any, b: any) => b.localeCompare(a));
	},
	short(seasonId: string) {
		const first = seasonId.substr(0, 2);
		const second = seasonId.substr(2, 2);
		return `${first}/${second}`;
	},
	long(seasonId: string) {
		// works only for year 2000 and after
		return `20${this.short(seasonId)}`;
	},
	display(seasonId: string) {
		return this.long(seasonId);
	},
	toSeason(seasonId: string): Season {
		return {
			seasonId,
			shortDescription: this.short(seasonId),
			longDescription: this.long(seasonId),
			display: this.display(seasonId),
		};
	}
};

export default SEASON_SERVICE;
