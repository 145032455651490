export interface CustomComponent {
	component: any; // Vue component
	props?: any;
}

export interface Box extends CustomComponent {
	id?: string;
	title?: string;
}

export enum GameContentType {
	TELEGRAMM = 'telegramm',
	BILDER = 'bilder',
	BERICHT = 'bericht',
	STATS = 'stats',
	UNDEFINED = '',
}
export const GAME_CONTENTS = {
	[GameContentType.TELEGRAMM]: 'Telegramm',
	[GameContentType.BILDER]: 'Bilder',
	[GameContentType.BERICHT]: 'Bericht',
	[GameContentType.STATS]: 'Statistik',
	[GameContentType.UNDEFINED]: '',
};

export enum PlayerContentType {
	STATS = 'stats',
	PICTURES = 'bilder'
}
export const PLAYER_CONTENT_TYPE = {
	[PlayerContentType.STATS]: 'Statistiken',
	[PlayerContentType.PICTURES]: 'Bilder'
};

export interface Link {
	target: string;
	text?: string;
	iconName?: string;
	iconPack?: string;
	enabled: boolean;
	tooltip?: string;
}

export interface MetaProperty {
	property?: string; // OpenGraph
	name?: string; // Twitter
	itemprop?: string; // Google / Schema.org
	content: string;
}

export interface ImageDescription {
	src: string;
	alt?: string;
}

export interface HfPage {
	// by allowing undefined, default header-elements are not overwritten
	readonly headTitle: string | undefined;
	readonly headDescription: string | undefined;
	readonly canonicalUrl: string;
	readonly imageDesc?: ImageDescription;
	readonly boxes?: Box[];
}

export interface HfCalendar {
	minDate: Date;
	maxDate: Date;
	isLoading: boolean;
	events?: Date[];
	onDateChange(date: Date): void;
	onMonthChange(monthIdx: number): void;
	onYearChange(year: number): void;
}

export interface FilterValue<T> {
	value: T;
	name: string;
	iconName: string;
	iconPack: string;
}
