import Vue from 'vue';
import BaseService from '@/services/base.service';
import {HttpResponse} from 'vue-resource/types/vue_resource';
import {PlayoffSerieTO} from '@/model/ranking.model';
import dayjs from 'dayjs';
import {DATE_FORMAT_ISO} from '@/utils/date.util';


export class PlayoffService extends BaseService {

	public playoffSerieByGameId(gameId: string): Promise<PlayoffSerieTO> {
		const url = this.url(`playoffs/game/${gameId}`);
		return Vue.http.get(url).then((r: HttpResponse) => r.data);
	}

	public playoffSeriesAt(leagueId: string, date?: Date): Promise<PlayoffSerieTO[]> {
		if (!leagueId) {
			Vue.$log.warn(`No playoff loaded as no leagueId is given`);
			return new Promise(() => {});
		}
		const dateParam = dayjs(date).format(DATE_FORMAT_ISO);
		const url = this.url(`playoffs/by-date/${leagueId}?dateStr=${dateParam}`);
		return Vue.http.get(url).then((r: HttpResponse) => r.data);
	}

	public playoffSeriesSeason(leagueId: string, seasonId: string): Promise<PlayoffSerieTO[]> {
		const url = this.url(`playoffs/${leagueId}/${seasonId}`);
		return Vue.http.get(url).then((r: HttpResponse) => {
			return r.status === 204 ? [] : r.data;
		});
	}

}
