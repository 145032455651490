import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export interface UserData {
	favouriteTeamId: string | undefined;
	showAds: boolean;
	developerMode: boolean;
}

export const state: UserData = {
	favouriteTeamId: undefined,
	showAds: true,
	developerMode: process.env.NODE_ENV !== 'production',
};

const store = {
	state,
	namespaced: true,
	getters,
	actions,
	mutations,
};

export default store;
