














import {Component, Vue} from 'vue-property-decorator';
import {DO_TOGGLE_DEVELOPMENT_MODE} from '@/store/user/mutations';

@Component({
  components: {},
})
export default class VersionInfo extends Vue {

  toggleDevelopmentMode() {
    this.$store.commit(DO_TOGGLE_DEVELOPMENT_MODE);
  }

  get appVersion() {
    return Vue.prototype.$appVersion;
  }

  get appVersionLabel() {
    return `Version ${this.appVersion}`;
  }

}
