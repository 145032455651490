import Vue from 'vue';
import BaseService from '@/services/base.service';
import {HttpResponse} from 'vue-resource/types/vue_resource';
import {Article, Headline, Topstory} from '@/model/article/article.model';
import dayjs from 'dayjs';
import {DATE_FORMAT_ISO} from '@/utils/date.util';
import COMMON_UTIL from '@/utils/common.util';
import {ImageDescription} from '@/model/site/components.model';

/**
 * This should be named 'NewsArticleService`
 */
export class ArticleService extends BaseService {

	public topstory(teamId?: string | null): Promise<Topstory> {
		const url = this.url(`topstory`);
		return Vue.http.get(url, {params: {teamId: teamId}})
			.then((r: HttpResponse) => r.data)
			.catch((error: any) => {});
	}

	public news(): Promise<Array<Article>> {
		// TODO [improvement] use config for latest news
		return this.latest(20);
	}

	public latest(count: number, keyword?: string): Promise<Array<Article>> {
		let url = this.url(`news/latest/${count}`);
		if (keyword) {
			url += `?keyword=${keyword}`;
		}
		return Vue.http.get(url).then((r: HttpResponse) => {
			if (r.data) {
				// TODO [improvement] is there a better/another way
				r.data.forEach((n: any) => n.createdAt = new Date(n.timestamp));
			}
			return r.data;
		});
	}

	public latestFocus(): Promise<Article> {
		let url;
		if (this.isMocked()) {
			url = this.hfUrl(`news/detail/42`);
		} else {
			url = this.url(`news/latest-focus`);
		}
		return Vue.http.get(url).then((r: HttpResponse) => {
			if (r.status === 204 || !r.data) {
				return Promise.reject(`Es wurde kein aktueller News-Beitrag gefunden`);
			}
			return {...r.data, createdAt: new Date(r.data.timestamp)};
		}).catch((error: any) => Promise.reject(`Es gab einen Fehler ${error}`));
	}

	public detail(id: number): Promise<Article> {
		let url;
		if (this.isMocked()) {
			url = this.hfUrl(`news/detail/${this.mockId(id)}`);
		} else {
			url = this.url(`news/${id}`);
		}
		return Vue.http.get(url).then((r: HttpResponse) => {
			if (r.status === 204 || !r.data) {
				return Promise.reject(`Es existiert kein News-Beitrag mit der ID #${id}`);
			}
			return {...r.data, createdAt: new Date(r.data.timestamp)};
		}).catch((error: any) => Promise.reject(`Es gab einen Fehler: ${error}`));
	}

	public headlines(date: Date): Promise<Headline[]> {
		const dateParam = dayjs(date).format(DATE_FORMAT_ISO);
		const url = this.url(`headlines/date/${dateParam}`);
		return Vue.http.get(url).then((r: HttpResponse) => r.data);
	}

	public dates(year: number, month: number): Promise<Date[]> {
		const url = this.url(`headlines/dates/${year}/${month + 1}`);
		return Vue.http.get(url).then((r: HttpResponse) => {
			return r.data.map((dateStr: string) => dayjs(dateStr).toDate());
		});
	}

	public imageDesc(article: Article): ImageDescription | undefined {
		if (article.pictureURL) {
			return {src: article.pictureURL};
		}
		if (!article.keywords || !article.keywords.length) {
			return undefined;
		}
		const images = article.keywords.sort((k1, k2) => {
			if (k1.image && !k2.image) return -1;
			if (!k1.image && k2.image) return 1;
			if (k1.type === 'PLAYER' && k2.type !== 'PLAYER') return -1;
			if (k1.type !== 'PLAYER' && k2.type === 'PLAYER') return 1;
			return 0;
		});
		const image = images[0];
		if (image) {
			let desc: any;
			if (image.type === 'PLAYER') {
				desc = {src: COMMON_UTIL.paths.playerPortrait(image.value)};
			}
			if (image.type === 'TEAM') {
				desc = {src: COMMON_UTIL.paths.clubLogoPath(image.value)};
			}
			if (image.resolved) {
				desc = {...desc, alt: image.resolved};
			}
			return desc;
		}
		return undefined;
	}

}
