












import HfCopyright from '@/components/shared/HfCopyright.vue';
import TheFooter from '@/components/layout/TheFooter.vue';
import {Component, Vue} from 'vue-property-decorator';
import {ArticleService} from '@/services/article.service';
import {Settings} from '@/model/site/settings.model';
import {GameService} from '@/services/game.service';
import {RankingService} from '@/services/ranking.service';
import {PageService} from '@/services/page.service';
import {GameDetailService} from '@/services/game-detail.service';
import {PlayoffService} from '@/services/playoff.service';
import {RESET_WARNINGS, SET_CONFIGURATION} from '@/store/context.store';
import {SiteConfig} from './model/site/context.model';
import {BackgroundArticleService} from '@/services/background-article.service';
import {ContentService} from '@/services/content.service';
import EventService from './services/event.service';
import TheNavigation from '@/components/layout/navigation/TheNavigation.vue';
import {HealthService} from '@/services/health.service';
import {ROUTES} from '@/router';
import VersionInfo from '@/components/layout/util/VersionInfo.vue';
import {RosterService} from '@/services/roster.service';

@Component({
  components: {VersionInfo, TheNavigation, TheFooter, HfCopyright},
  provide() {

    const settings = {} as Settings;
    Object.defineProperty(settings, 'innerWidth', {
      enumerable: true,
      get: () => window.innerWidth,
    });

    return {
      settings,
      articleService: new ArticleService(),
      gameService: new GameService(),
      playoffService: new PlayoffService(),
      rankingService: new RankingService(),
      pageService: new PageService(),
      gameDetailService: new GameDetailService(),
      backgroundArticleService: new BackgroundArticleService(),
      contentService: new ContentService(),
      eventService: new EventService(),
      rosterService: new RosterService(),
    };

  },
  metaInfo: {
    title: 'Hockeyfans',
    titleTemplate: '%s | Hockeyfans.ch',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        // TODO update claim
        content: 'hockeyfans.ch ist die umfassendste Schweizer Eishockeyseite, mit Berichten zu allen Spielen und Infos zu jedem Club/Spieler.'
      }, {
        vmid: 'keywords',
        name: 'keywords',
        content: 'Eishockey,Schweiz,Swiss League,National League,Champions Hockey League,CHL,Nati,Nationalmannschaft,Fans,Hockeyfans',
      }
    ]
  },
})
export default class App extends Vue {

  static readonly SERVER_ERROR_PAGE = '/503';
  static readonly DEFAULT_HEALTH_TIMEOUT_MS = 10000;

  siteConfig!: SiteConfig;
  healthService = new HealthService();

  beforeMount() {
    this.$store.commit(RESET_WARNINGS);
  }

  beforeUpdate() {
    this.healthCheck();
  }

  private healthCheck() {
    if (this.siteConfig.healthCheckTimeoutDisabled) {
      return;
    }
    if (this.$router.currentRoute.path === App.SERVER_ERROR_PAGE) {
      this.healthService.ping(this.siteConfig.healthCheckTimeout || App.DEFAULT_HEALTH_TIMEOUT_MS)
        .then(() => {
          const lastPath: string = this.$route.query.lastPath || '/';
          this.$router.push(lastPath);
        }).catch((error) => {
          Vue.$log.error('Backend is still down / not reachable', error);
        }
      );
      return;
    } else {
      this.healthService.ping(this.siteConfig.healthCheckTimeout || App.DEFAULT_HEALTH_TIMEOUT_MS)
        .then(() => Vue.$log.debug('Backend is up and running'))
        .catch(error => {
          Vue.$log.error('Backend is down / not reachable', error);
          this.$router.push({name: ROUTES.ERROR_PAGE, query: {lastPath: this.$router.currentRoute.path}});
        });
    }
  }

  beforeCreate() {
    const configuration = require('@/assets/static/configuration.json');
    this.siteConfig = configuration.site;
    Vue.$log.debug('Using configuration', configuration);
    this.$store.commit(SET_CONFIGURATION, configuration);
  }

  get nonavbar() {
    return this.$route.query.nonavbar === '1';
  }

  get standaloneStatsMode(): boolean {
    const mode = this.siteConfig && this.siteConfig.standaloneStatsMode;
    Vue.$log.debug('Using standalone-stats-mode', mode);
    return mode;
  }
}
