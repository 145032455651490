








































import {Component, Prop, Vue} from 'vue-property-decorator';
import HfCopyright from '@/components/shared/HfCopyright.vue';
import BreakpointInfo from '@/components/layout/util/BreakpointInfo.vue';
import ErrorDisplay from '@/components/layout/util/ErrorDisplay.vue';
import ClearStorage from '@/components/layout/util/ClearStorage.vue';
import {Getter} from 'vuex-class';
import AdToggler from '@/components/layout/util/AdToggler.vue';
import {HF_INSTAGRAM, HF_YOUTUBE} from '@/utils/common.util';
import {GET_DEVELOPER_MODE} from '@/store/user/getters';
import dayjs from 'dayjs';
import VersionInfo from '@/components/layout/util/VersionInfo.vue';
import { REFRESHABLE } from '@/store/context.store';

@Component({
  components: {VersionInfo, AdToggler, HfCopyright, ErrorDisplay, BreakpointInfo, ClearStorage},
})
export default class TheFooter extends Vue {

  @Prop({default: false}) standaloneStatsMode!: boolean;

  @Getter(GET_DEVELOPER_MODE) developerMode!: boolean;
  @Getter(REFRESHABLE) refreshable!: boolean;

  get youtube() {
    return HF_YOUTUBE;
  }

  get instagram() {
    return HF_INSTAGRAM;
  }

  get year() {
    return dayjs().format('YYYY');
  }

  refresh() {
    this.$root.$emit('refresh-page');
  }

}
