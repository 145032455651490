export const HF_BASE = 'http://www.hockeyfans.ch';
export const HF_RSS_NEWS = 'http://www.hockeyfans.ch/news/rss';
export const HF_INSTAGRAM = 'http://instagram.com/hockeyfans.ch';
export const HF_YOUTUBE = 'https://www.youtube.com/channel/UCQOIQJ0UDRrSTjnmmyEO-kA';
const CLUBLOGO = `${HF_BASE}/vue.js/static/image/clublogo`;
const HF_ARCHIV = 'http://www.hockeyarchiv.ch';
const HF_DEVEL = 'http://www.hockeydevel.ch';
const HF_SITE_PROD = 'http://site.hockeyfans.ch';
const HF_PROD_LIVE = 'http://www.hockeyfans.ch';
const HF_SITE_DEVEL = 'http://site.hockeydevel.ch';
const HF_SITE_LOCAL = 'http://localhost:5555';
const HF_SITE_LOCAL_TUNNEL = 'https://hockeyfans.localtunnel.me';
const HF_PRERENDER_LOCAL = 'http://localhost:3000';
export const LOCALE = 'de';
export const TWITTER_HANDLE = '@hockeyfans_ch';
export const FB_APP_ID = '112125439509';

const COMMON_UTIL = {
	defaultLoader: 'refresh',
	PARAM_WILDCARD: 'all', // see ch.hockeyfans.rest.stats.QueryParamExtractor#PARAM_WILDCARD_KEY
	PARAM_BOTH: 'both', // see ch.hockeyfans.core.parameter.HA#BOTH
	validParams: function (params: any): boolean {
		return params.filter((val: any) => !val || !val.trim()).length === 0;
	},
	links: {
		gameLink(gameId: string): string {
			return `${HF_BASE}/spiel/telegramm/${gameId}`;
		},
		playerPictures(playerId: string, maxWidth: number): string {
			return `${HF_ARCHIV}/bilder/galleria.php?spielerId=${playerId}&format=json&maxWidth=${maxWidth}`;
		},
		develLinks(path: string): any[] {
			const host = document.documentURI;
			return [
				{type: 'devel', url: `${HF_SITE_DEVEL}${path}`},
				{type: 'local', url: `${HF_SITE_LOCAL}/#${path}`},
				{type: 'pre-prod', url: `${HF_SITE_PROD}${path}`},
				{type: 'prod-live', url: `${HF_PROD_LIVE}${path}`, target: 'hockeyfans'},
				{type: 'tunnel', url: `${HF_SITE_LOCAL_TUNNEL}/#${path}`},
				{type: 'prerender', url: `${HF_PRERENDER_LOCAL}/${document.documentURI}`},
			];
		}
	},
	paths: {
		clubLogoPath(teamId: string): string {
			// once logos exists in SVG for most clubs, modern gets legacy ...
			return `${CLUBLOGO}/legacy/${teamId}.png`;
		},
		clubLogoAltPath(teamId: string): string {
			return this.modernLogoPath(teamId);
		},
		modernLogoPath(teamId: string): string {
			// ... and vice versa ;-)
			return `${CLUBLOGO}/modern/${teamId}.svg`;
		},
		playerPortrait(playerId: string): string {
			return `${HF_ARCHIV}/spieler/${playerId}/portrait.jpg`;
		},
		playerPortraitPlaceholder(): string {
			return this.playerPortrait('placeholder');
		},
		eventLogo(logoPath: string): string {
			return `${HF_BASE}/${logoPath}`;
		}
	}
};


/**
 * Use only for debugging/testing purposes.
 */
export function busySleep(seconds: number) {
	const now = new Date().getTime();
	while (new Date().getTime() < now + seconds * 1000) {
		/* do nothing */
	}
}

export function sleep(millis: number): Promise<any> {
	return new Promise(resolve => setTimeout(resolve, millis));
}

export async function sleepAsync(millis: number) {
  await sleep(millis);
}

export function hasKeys(object: any) {
	return typeof object === 'object' && Object.keys(object).length > 0;
}

export function toArray(object: any): any[] {
	const result: any[] = [];
	Object.keys(object).forEach(key => result.push(object[key]));
	return result;
}

export function zeroPad(num: number, places: number): number | string {
  const zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
}

export function isNumeric(value: any): boolean {
	if (!value) {
		return false;
	}
	try {
		return parseInt(value).toString() === value;
	} catch (exception) {
		return false;
	}
}

/** Use this for any declaring reactive properties as {} is not applicable. */
export const nil: any = () => null;

export default COMMON_UTIL;
