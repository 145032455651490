import Vue from 'vue';
import BaseService from '@/services/base.service';
import {HttpResponse} from 'vue-resource/types/vue_resource';
import {GameInfo, IndexedGames} from '@/model/game/game.model';
import dayjs from 'dayjs';
import {GameTypeId, LeagueId} from '@/model/core/core-data.model';
import {DATE_FORMAT_ISO} from '@/utils/date.util';
import {isArray} from 'util';


export class GameService extends BaseService {

	public topgame(teamId: string | null): Promise<IndexedGames> {
		const params = new URLSearchParams();
		if (teamId) {
			this.addParam(params, {}, 'teamId', teamId);
		}
		const url = this.url(`games/topgame?${params}`);
		return Vue.http.get(url).then((r: HttpResponse) => r.data);
	}

	/**
	 * Note that endpoint delivers both: { "Nationalliga A": [], "a": [], ...
	 */
	public last(): Promise<IndexedGames> {
		const url = this.url(`games/last`);
		return Vue.http.get(url).then((r: HttpResponse) => r.data);
	}

	/**
	 * Note that endpoint delivers both: { "Nationalliga A": [], "a": [], ...
	 */
	public next(): Promise<IndexedGames> {
		const url = this.url(`games/next`);
		return Vue.http.get(url).then((r: HttpResponse) => r.data);
	}

	/**
	 * Note that endpoint delivers both: { "Nationalliga A": [], "a": [], ...
	 */
	public current(): Promise<IndexedGames> {
		const url = this.url(`games/current`);
		return Vue.http.get(url).then((r: HttpResponse) => r.data);
	}

	public datesPerLeague(year: number, monthIdx: number, leagueId: LeagueId | undefined): Promise<Date[]> {
		if (!leagueId) {
			return Promise.resolve([]);
		}
		const params = new URLSearchParams();
		this.addParam(params, {}, 'leagueId', leagueId);
		const url = this.url(`games/dates/${year}/${monthIdx + 1}?${params}`);
		return Vue.http.get(url).then((r: HttpResponse) => {
			return r.data.map((dateStr: string) => dayjs(dateStr).toDate());
		});
	}

	public dates(year: number, monthIdx: number): Promise<Date[]> {
		const url = this.url(`games/dates/${year}/${monthIdx + 1}`);
		return Vue.http.get(url).then((r: HttpResponse) => {
			return r.data.map((dateStr: string) => dayjs(dateStr).toDate());
		});
	}

	public gamesAt(date: Date): Promise<GameInfo[]> {
		const dateParam = dayjs(date).format(DATE_FORMAT_ISO);
		const url = this.url(`games/at?date=${dateParam}`);
		return Vue.http.get(url)
			.then((r: HttpResponse) => {
				if (r.status === 204 || !r.data) {
					return Promise.reject(`Keine Spiele gefunden`);
				}
				return r.data;
			})
			.catch((e: any) => {
				if (e.status === 404) {
					return Promise.reject(`Keine Spiele gefunden`);
				}
				return Promise.reject('Es gab einen internen Fehler');
			});
	}

	lastNextByTeam(teamId: string, cnt: number): Promise<GameInfo[]> {
		const url = this.url(`games/team/${teamId}?cnt=${cnt}`);
		return Vue.http.get(url).then((r: HttpResponse) => r.data);
	}

	byLeagueAndSeason(seasonId: string, leagueId?: LeagueId, gameTypeId?: GameTypeId | GameTypeId[]): Promise<GameInfo[]> {
		const params = new URLSearchParams();
		params.append('seasonId', seasonId);
		if (leagueId) {
			params.append('leagueId', leagueId);
		}
		if (gameTypeId) {
			const gameTypeIdParam = Array.isArray(gameTypeId) ? gameTypeId.join(',') : gameTypeId;
			params.append('gameTypeId', gameTypeIdParam);
		}
		if (!leagueId && !gameTypeId) {
			throw 'Either leagueId or gameTypeId must be set';
		}
		const url = this.url(`games?${params}`);
		return Vue.http.get(url).then((r: HttpResponse) => r.data).catch(() => {
		});
	}

}
