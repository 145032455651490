













import {Component, Vue} from 'vue-property-decorator';
import {clearStorage} from '@/store/index.store';

@Component({
  components: {},
})
export default class ClearStorage extends Vue {
  clearStorage() {
    clearStorage();
  }
}
