import Vue from 'vue';
import Vuex from 'vuex';
import dataStore from './data';
import contextStore  from './context.store';
import userStore from './user';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const STORAGE = 'storage';

const vuexLocal = new VuexPersistence({
	key: STORAGE,
	modules: ['user', 'context'],
	storage: window.localStorage,
});

// note that constants to not work in GETTER/MUTATION annotations

const store = new Vuex.Store({
	modules: {
		data: dataStore,
		context: contextStore,
		user: userStore,
	},
	plugins: [vuexLocal.plugin],
});

store.subscribe((mutation: any, payload: any) => {
	Vue.$log.debug('Mutation', mutation, payload);
});

export function clearStorage() {
	Vue.$log.info(`Clear '${STORAGE}' from Local Storage`);
	localStorage.removeItem(STORAGE);
}

export default store;
