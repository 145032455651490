import { UserData } from '.';

export const SET_FAVOURITE_TEAM_ID = 'setFavouriteTeamId';
const REMOVE_FAVOURITE_TEAM_ID = 'removeFavouriteTeamId';
const TOGGLE_ADS = 'toggleAds';
const TOGGLE_DEVELOPMENT_MODE = 'toggleDeveloperMode';

export const DO_SET_FAVOURITE_TEAM_ID = `user/${SET_FAVOURITE_TEAM_ID}`;
export const DO_REMOVE_FAVOURITE_TEAM_ID = `user/${REMOVE_FAVOURITE_TEAM_ID}`;
export const DO_TOGGLE_ADS = `user/${TOGGLE_ADS}`;
export const DO_TOGGLE_DEVELOPMENT_MODE = `user/${TOGGLE_DEVELOPMENT_MODE}`;

const MUTATIONS = {
	[SET_FAVOURITE_TEAM_ID](data: UserData, value: any): void {
		data.favouriteTeamId = value;
	},
	[REMOVE_FAVOURITE_TEAM_ID](data: UserData): void {
		data.favouriteTeamId = undefined;
	},
	[TOGGLE_ADS](data: UserData): void {
		data.showAds = !data.showAds;
	},
	[TOGGLE_DEVELOPMENT_MODE](data: UserData): void {
		data.developerMode = !data.developerMode;
	},
};



export default MUTATIONS;
