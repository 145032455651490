const message = {
	'label': {
		'attendanceMax': 'Max.',
		'attendanceAvg': 'Schnitt',
		'attendanceMin': 'Min.',
		'g': 'Tore',
		'a': 'Assists',
		'p': 'Punkte',
		'pim': 'Strafminuten',
		'plmi': 'Plus/Minus'
	},
	'leagueIdLong': {
		'all': 'Alle Ligen',
		'a': 'National League',
		'b': 'Swiss League',
		'c': 'Swiss Cup',
		'cup': 'Swiss Cup',
		'chl': 'Champions Hockey League',
		'fra': 'Frauen-Nationalmannschaft',
		's': 'Spengler Cup',
		't': 'Testspiele',
		'n': 'A-Nationalmannschaft',
		'u20': 'U20-Nationalmannschaft',
		'u18': 'U18-Nationalmannschaft',
		'f18': 'U18-Nationalm. (Frauen)',
	},
	'roundIdShort': {
		'p9': 'R-9/10',
		'p7': 'R-7/8',
		'p5': 'R-5/6',
		'p16': '1/16.-F',
		'p8': '⅛-F',
		'p4': '¼-F',
		'p4_1': '1. ¼-F',
		'p4_2': '2. ¼-F',
		'p4_3': '3. ¼-F',
		'p4_4': '4. ¼-F',
		'p2': 'HF',
		'p2_1': '1. HF',
		'p2_2': '2. HF',
		'p3': 'R-3/4',
		'p1': 'Final',
		'pb': 'P-Out',
		'pc': 'LQ',
		'pl': 'Platz.',
		'r': 'RL',
	},
	'roundIdLong': {
		'p9': 'Sp. um Rang 9/10',
		'p7': 'Sp. um Rang 7/8',
		'p5': 'Sp. um Rang 5/6',
		'p16': '1/16.-Final',
		'p8': 'Achtelfinal',
		'p4': 'Viertelfinal',
		'p4_1': '1. Viertelfinal',
		'p4_2': '2. Viertelfinal',
		'p4_3': '3. Viertelfinal',
		'p4_4': '4. Viertelfinal',
		'p2': 'Halbfinal',
		'p2_1': '1. Halbfinal',
		'p2_2': '2. Halbfinal',
		'p3': 'Bronze-Spiel',
		'p1': 'Final',
		'pb': 'Playout-Final',
		'pc': 'Liga-Qualifikation',
		'pl': 'Platzierungsspiel',
		'r': 'Relegation',
	},
	'leagueIdShort': {
		'all': 'Alle',
		'a': 'NL',
		'b': 'SL',
		'c': 'Cup',
		'cup': 'Cup',
		'chl': 'CHL',
		'fra': 'Nati ♀',
		's': 'SC',
		't': 'T',
		'n': 'Nati',
		'u20': 'U20',
		'u18': 'U18',
		'f18': 'F18',
	},
	'gameTypeId': {
		'all': 'Alle',
		'm': 'Qualifikation',
		'p': 'Play-off',
		'l': 'Liga-Qualifikation',
		'r': 'Relegation',
		't': 'Testspiel',
	},
	'gameTypeIdShort': {
		'all': 'Alle',
		'm': 'Q',
		'p': 'P',
		'l': 'L',
		'r': 'R',
		't': 'T',
	},
	'homeAway': {
		'both': 'Alle',
		'a': 'Auswärts',
		'h': 'Heim'
	},
	'position': {
		'G': 'Torhüter',
		'S': 'Stürmer',
		'V': 'Verteidiger',
		'T': 'Head-Coach',
		'A': 'Assistenz-Trainer',
		'null': 'unbekannt'
	},
	'country': {
		'bel': 'Belgien',
		'bos': 'Bosnien-Herzegowina',
		'bul': 'Bulgarien',
		'can': 'Kanada',
		'cze': 'Tschechien',
		'dan': 'Dänemark',
		'deu': 'Deutschland',
		'est': 'Estland',
		'fin': 'Finnland',
		'fra': 'Frankreich',
		'gbr': 'Grossbritannien',
		'gri': 'Griechenland',
		'hol': 'Holland',
		'ira': 'Iran',
		'irl': 'Irland',
		'isl': 'Island',
		'isr': 'Israel',
		'ita': 'Italien',
		'jap': 'Japan',
		'jug': 'Jugoslawien',
		'kas': 'Kasachstan',
		'kro': 'Kroatien',
		'let': 'Lettland',
		'lib': 'Liberia',
		'lit': 'Litauen',
		'lux': 'Luxenbourg',
		'mex': 'Mexiko',
		'ned': 'Niederlande',
		'nor': 'Norwegen',
		'oes': 'Österreich',
		'pol': 'Polen',
		'por': 'Portugal',
		'rum': 'Rumänien',
		'rus': 'Russland',
		'slk': 'Slowakei',
		'sln': 'Slowenien',
		'spa': 'Spanien',
		'sui': 'Schweiz',
		'swe': 'Schweden',
		'tur': 'Türkei',
		'ukr': 'Ukraine',
		'ung': 'Ungarn',
		'usa': 'USA',
		'wru': 'Weissrussland',
		'null': ''
	},
	'grid': {
		'first': 'Erste',
		'last': 'Letzte',
		'loadingOoo': 'Lade Daten ...',
		'next': 'Nächste',
		'noRowsToShow': 'Keine Daten',
		'of': 'von',
		'page': 'Seite',
		'previous': 'Vorherige',
		'to': 'bis',
	},

};
export default message;
