














import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {DO_TOGGLE_ADS} from '@/store/user/mutations';
import { GET_SHOW_ADS } from '@/store/user/getters';

@Component({
  components: {},
})
export default class AdToggler extends Vue {

  @Getter(GET_SHOW_ADS) showAds!: boolean;

  toggleAds() {
    this.$store.commit(DO_TOGGLE_ADS);
  }

}
