





















































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
// import {Getter} from 'vuex-class';
import {Navigation, NavigationItem} from '@/model/site/navigation.model';
import FavouriteTeamSelection from '@/components/layout/navigation/FavouriteTeamSelection.vue';
import VersionInfo from '@/components/layout/util/VersionInfo.vue';
import BreakpointInfo from '@/components/layout/util/BreakpointInfo.vue';
import DevelLink from '@/components/layout/util/DevelLink.vue';
// import {GET_DEVELOPER_MODE} from '@/store/user/getters';
import NavigationItemContent from '@/components/layout/navigation/NavigationItemContent.vue';
import Slide from '@/components/layout/navigation/burger/Slide.vue';
import {BulmaAccordion, BulmaAccordionItem} from 'vue-bulma-accordion';
import MobileMixin from '@/mixins/mobile.mixin';
import {REFRESHABLE} from '@/store/context.store';

@Component({
    mixins: [MobileMixin],
    components: {
      BulmaAccordion, BulmaAccordionItem, Slide, NavigationItemContent, DevelLink, BreakpointInfo, VersionInfo, FavouriteTeamSelection},
    }
  )
  export default class TheNavigation extends Vue {

    @Prop({default: false}) standaloneStatsMode!: boolean;
    // @Getter(GET_DEVELOPER_MODE) developerMode!: boolean;
    developerMode = false;

    navigation!: Navigation;
    open: boolean = false;

    beforeMount() {
      this.navigation = require('@/assets/static/navigation.json');
    }

    get showOnMobileScreen(): boolean {
      return this.isMobile();
    }

    close() {
      this.open = false;
    }

    closeOnNavigate(target: any) {
      if (target !== this.$route.path) {
        this.$router.push(target);
      }
      this.handleCloseMenu();
    }

    handleOpenMenu() {
      this.open = true;
    }

    handleCloseMenu() {
      setTimeout(() => this.open = false, 1000);
    }

    click(item: NavigationItem) {
      Vue.$log.debug(`clicked navigation item`, item);
    }

    hasActiveItem(item: NavigationItem) {
      const path = this.$route.path;
      const isPath = path.indexOf(item.target) !== -1;
      if (!isPath || !item.subitems) {
        return false;
      }
      return item.subitems.filter((i: NavigationItem) => path.indexOf(i.target) !== -1).length > 0;
    }

    get nonavbar() {
      return this.$route.query.nonavbar === '1';
    }

    get navigationItems(): NavigationItem[] {
      if (!this.navigation) {
        return [];
      }
      let items: NavigationItem[] = [];
      if (this.standaloneStatsMode) {
        const statsItems = this.navigation.items.find(item => item.id === 'stats-index');
        if (statsItems && statsItems.subitems) {
          items = statsItems.subitems;
          items.forEach(item => {
            const statsTarget = `stats/${item.target}`;
            if (item.target && !item.target.includes('stats')) {
              item.target = statsTarget;
            }
          });
        }
      } else {
        items = this.navigation.items;
      }
      return items.filter(item => !item.disabled);
    }

    hasSubitems(item: NavigationItem): boolean {
      return this.subitems(item).length > 0;
    }

    subitems(item: NavigationItem): NavigationItem[] {
      if (!item || !item.subitems) {
        return [];
      }
      return item.subitems.filter(subitem => !subitem.disabled);
    }

    target(item: NavigationItem, subItem?: NavigationItem) {
      if (subItem) {
        if (subItem.target && subItem.target.startsWith('/')) {
          return subItem.target;
        } else if (item.baseTarget) {
          return `${item.baseTarget}/${subItem.target}`;
        } else if (subItem.separator) {
          return undefined;
        } else {
          Vue.$log.warn('Illegal navigation: subItem does not start with "/" altough baseTarget is not set item parent item', item, subItem);
          return item.target;
        }
      } else {
        return item.target;
      }
    }

    itemId(item: NavigationItem) {
      // used in (mobile-)navigation.test.js
      return `nav-${item.id}`;
    }

  }
