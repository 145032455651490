import dayjs from 'dayjs';

export default class Player {

	public static readonly UNKNOWN: string = 'unbekannt';

	public readonly currentTeamId: any;
	public readonly alternateTeamId: any;
	public readonly position: string;
	public readonly nationalityId1: any;
	public readonly nationalityId2: any;

	constructor(private data: any) {
		this.currentTeamId = data.currentTeamId;
		this.alternateTeamId = data.alternateTeamId;
		this.position = data.position;
		this.nationalityId1 = data.nationalityId_1;
		this.nationalityId2 = data.nationalityId_2;
	}

	get id() {
		return this.data.playerId;
	}

	exists(): boolean {
		return this.data;
	}

	name(): string {
		return `${this.data.firstName} ${this.data.lastName}`;
	}

	birthday(format?: string): string | undefined {
		if (!this.data.birthday) {
			return undefined;
		}
		const formatUse = format || 'd. MMMM YYYY';
		return dayjs(this.data.birthday).format(formatUse);
	}

	age(float?: boolean): number | undefined {
		if (!this.data.birthday) {
			return undefined;
		}
		let age = dayjs().diff(dayjs(this.data.birthday), 'year', float);
		if (float) {
			age = Math.round(age * 10) / 10;
		}
		return age;
	}

	height(): string | undefined {
		return this.data.height ? `${this.data.height} cm` : undefined;
	}

	weight(): string | undefined {
		return this.data.weight ? `${this.data.weight} kg` : undefined;
	}

	isGoalie(): boolean {
		return this.data.position === 'G';
	}

	isCoachOrAssistant(): boolean {
		return this.data.position === 'T' || this.data.position === 'A';
	}

	jerseyNr() {
		if (this.isCoachOrAssistant()) {
			return null;
		}
		return this.data.jerseyNr;
	}

	isJunior(): boolean {
		return this.data.junior;
	}

}

export interface IPlayerBirthday {
	playerId: string;
	firstName: string;
	birthdate: Date;
	lastName: string;
	ageInYears: number;
	teamId?: string;
	teamDisplay?: string;
}

export interface IPlayerPicture {
	id: string;
	url: string;
	copyright: string;
	height: number;
	width: number;
}
