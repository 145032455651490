
interface CountryCodes {
	[key: string]: CountryCode;
}
interface CountryCode {
	iso2: string;
}

const COUNTRIES: CountryCodes = {
	can: {iso2: 'ca'},
	cze: {iso2: 'cz'},
	dan: {iso2: 'dk'},
	deu: {iso2: 'de'},
	fin: {iso2: 'fi'},
	fra: {iso2: 'fr'},
	gbr: {iso2: 'gb'},
	ita: {iso2: 'it'},
	jap: {iso2: 'jp'},
	kas: {iso2: 'kz'},
	kor: {iso2: 'kr'},
	let: {iso2: 'lv'},
	nor: {iso2: 'no'},
	oes: {iso2: 'at'},
	rus: {iso2: 'ru'},
	slk: {iso2: 'sk'},
	sln: {iso2: 'si'},
	sui: {iso2: 'ch'},
	swe: {iso2: 'se'},
	ung: {iso2: 'hu'},
	usa: {iso2: 'us'},
	wru: {iso2: 'by'},
};

export const COUNTRY_SERVICE: any = {

	hfToCountryIso2: (hfCountryId: string) => {
		const countryCode: CountryCode = COUNTRIES[hfCountryId];
		if (!countryCode) {
			return undefined;
		}
		return countryCode.iso2;
	}

};
