











import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CountryFlag extends Vue {
  @Prop({required: true}) countryIso2!: string;
  @Prop({required: false, default: true}) squared!: boolean;
}
