import Vue from 'vue';
import {HttpResponse} from 'vue-resource/types/vue_resource';
import BaseService from '@/services/base.service';
import {NewsPageData} from '@/model/site/page.model';


export class PageService extends BaseService {

	newsPage(focusNewsId: number, newsConfig: any): Promise<NewsPageData> {
		if (!focusNewsId) {
			return Promise.reject('illegal parameter');
		}
		const params = new URLSearchParams();
		this.addParam(params, newsConfig, 'headlinesCnt', 20);
		this.addParam(params, newsConfig, 'otherNewsCnt', 2);
		this.addParam(params, newsConfig, 'playerNewsCnt', 4);
		this.addParam(params, newsConfig, 'teamNewsCnt', 4);
		this.addParam(params, newsConfig, 'topicNewsCnt', 6);
		const url = this.url(`pages/news/${this.mockId(focusNewsId)}?${params}`);
		return Vue.http.get(url).then((resp: HttpResponse) => resp.data);
	}

}
