import BaseService from './base.service';
import Vue from 'vue';
import {HttpResponse} from 'vue-resource/types/vue_resource';
import {GameTypeId, isExhibitionPreseason, LeagueId} from '@/model/core/core-data.model';
import {HfEvent} from '@/model/core/event.model';
import SEASON_SERVICE from '@/services/season.service';

export default class EventService extends BaseService {

	static readonly BASE_URL = `${process.env.VUE_APP_ROOT_HF}/data`;

	async loadDefinition(seasonId: string, dataGameTypeId: GameTypeId | GameTypeId[], leagueId?: LeagueId): Promise<string> {
		const gameTypeIdx = this.getGameTypeIdx(dataGameTypeId, leagueId);
		const leagueIdValue = leagueId || '';
		const url = `${EventService.BASE_URL}/${seasonId}-${leagueIdValue}-${gameTypeIdx}`;
		let result: string = '';
		await Vue.http.get(`${url}.xml`)
			.then((resp: HttpResponse) => result = resp.data)
			.catch((ignore: any) => {
			});
		return result;
	}

	async seasonsForEvent(dataGameTypeId: GameTypeId | GameTypeId[], leagueId?: LeagueId): Promise<string[]> {
		const seasons = SEASON_SERVICE.all(leagueId);
		if (seasons && seasons.length > 0) {
			return Promise.resolve(seasons.map(s => s.seasonId));
		}
		const url = `${EventService.BASE_URL}/events.json`;
		let result: any;
		const gameTypeIdx = this.getGameTypeIdx(dataGameTypeId, leagueId);
		await Vue.http.get(url)
			.then((resp: HttpResponse) => result = resp.data)
			.catch((ignore: any) => {
			});
		if (result) {
			if (leagueId) {
				if (result[leagueId] && result[leagueId][gameTypeIdx]) {
					return result[leagueId][gameTypeIdx];
				}
			} else {
				if (result[gameTypeIdx]) {
					return result[gameTypeIdx];
				}
			}
		}
		return [];
	}

	getGameTypeIdx(dataGameTypeId: GameTypeId | GameTypeId[], leagueId?: LeagueId) {
		let gameTypeIdx;
		if (Array.isArray(dataGameTypeId)) {
			gameTypeIdx = dataGameTypeId.join('-');
		} else {
			gameTypeIdx = dataGameTypeId;
		}
		if (leagueId === 'chl') {
			gameTypeIdx = 'chl';
		}
		return gameTypeIdx;
	}

	getEventForGameTypeOrLeague(gameTypeId: GameTypeId | GameTypeId[], leagueId: LeagueId): HfEvent | null {
		if (isExhibitionPreseason(gameTypeId, leagueId)) {
			return {
				title: 'Testspiele',
				subtitle: 'Alle Vorbereitungsspiele der Saison xy',
				leagueId: 't',
				gameTypeId: []
			};
		}
		if (leagueId === 'a') {
			return {
				title: 'National League',
				subtitle: 'National League Desc',
				leagueId: 'a',
				gameTypeId: ['m', 'p', 'r']
			};
		}
		if (leagueId === 'b') {
			return {
				title: 'Swiss League',
				subtitle: 'Swiss League Desc',
				leagueId: 'b',
				gameTypeId: ['m', 'p', 'r']
			};
		}
		return null;
	}
}
