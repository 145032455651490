import {sortLeagueIds} from '@/model/core/core-data.model';
import {Headline, Article} from '@/model/article/article.model';
import {extractLeagueIds, filterValidLeagues} from '@/store/data/index';
import {IndexedGames, GameInfo} from '@/model/game/game.model';
import {CURRENT_GAME_ID} from '@/store/data/getters';

const union = require('lodash/union');

export const SET_LASTGAMES: any = 'setLastgames';
export const SET_NEXTGAMES: any = 'setNextgames';
export const SET_TOPGAME: any = 'setTopgame';
export const SET_GAMES_AT_DATE: any = 'setGamesAtDate';
export const CLEAR_GAMES_AT_DATE: any = 'clearGamesAtDate';
export const SET_RANKING: any = 'setRanking';
export const SET_PLAYOFF: any = 'setPlayoff';
export const SET_HEADLINES_AT_DATE: any = 'setHeadlinesAtDate';
export const SET_LATEST_HEADLINES = 'setLatestHeadlines';
export const SET_PLAYER_NEWS = 'setPlayerNews';
export const SET_TEAM_NEWS = 'setTeamNews';
export const SET_OTHER_NEWS = 'setOtherNews';

const MUTATIONS = {
	[CLEAR_GAMES_AT_DATE](data: any, value: any): void {
		data.gamesAtDate = {};
	},
	[SET_TOPGAME](data: any, value: GameInfo): void {
		if (value) {
			data.topgame = value;
		}
	},
	[SET_LASTGAMES](data: any, value: any): void {
		if (value) {
			data.lastgames = filterValidLeagues(value);
			data.gamesCache = {...data.gamesCache, ...flatGames(data.lastgames)};
			data.leagueIds = sortLeagueIds(union(data.leagueIds, extractLeagueIds(data.lastgames)));
		}
	},
	[SET_NEXTGAMES](data: any, value: any): void {
		if (value) {
			data.nextgames = filterValidLeagues(value);
			data.gamesCache = {...data.gamesCache, ...flatGames(data.nextgames)};
			data.leagueIds = sortLeagueIds(union(data.leagueIds, extractLeagueIds(data.nextgames)));
		}
	},
	[SET_GAMES_AT_DATE](data: any, value: any): void {
		if (value) {
			const games = filterValidLeagues(value.data);
			data.gamesAtDateCache[value.dateStr] = games;
			data.gamesAtDate = games;
			data.gamesCache = {...data.gamesCache, ...flatGames(games)};
			data.leagueIds = sortLeagueIds(union(data.leagueIds, extractLeagueIds(data.gamesAtDate)));
		}
	},
	[CURRENT_GAME_ID](data: any, payload: any): void {
		data.currentGameId = payload;
	},
	[SET_RANKING](data: any, payload: any): void {
		if (payload) {
			let entries = payload.ranking;
			if (entries === '') {
				entries = '-';
			}
			data.rankingCache[payload.rankingId.key] = entries;
			data.ranking = entries;
		}
	},
	[SET_PLAYOFF](data: any, payload: any): void {
		if (payload) {
			data.playoffCache[payload.rankingId.key] = payload.playoff;
			data.playoff = payload.playoff;
		}
	},
	[SET_HEADLINES_AT_DATE](data: any, value: Headline[]): void {
		if (value) {
			data.headlinesAtDate = value;
		}
	},
	[SET_LATEST_HEADLINES](data: any, value: Headline[]): void {
		if (value) {
			data.latestHeadlines = value;
		}
	},
	[SET_PLAYER_NEWS](data: any, value: Article[]): void {
		if (value) {
			data.playerNews = value;
		}
	},
	[SET_TEAM_NEWS](data: any, value: Article[]): void {
		if (value) {
			data.teamNews = value;
		}
	},
	[SET_OTHER_NEWS](data: any, value: Article[]): void {
		if (value) {
			data.otherNews = value;
		}
	},
};

function flatGames(games: IndexedGames): any {
	const result: any = {};
	Object.keys(games).forEach(key => games[key].forEach(game => result[game.id] = game));
	return result;
}


export default MUTATIONS;
