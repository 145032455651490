import Vue from 'vue';
import BaseService from '@/services/base.service';

export class HealthService extends BaseService {

	ping(timeout: number): Promise<any> {
		const url = this.url('ping');
		return Vue.http.get(url, {timeout});
	}

}
