// @ts-ignore
import {AD_FORMATS_FOR_DEVICE, AdModel} from '@/model/ads/ads.model';
import Vue from 'vue';

export function refreshAds() {
	const w: any = window;
	if (w.gbucket && w.gbucket.refresh) {
		AD_FORMATS_FOR_DEVICE.forEach((adModel: AdModel) => {
			try {
				if (adModel.outstream) {
					if (document.getElementById(adModel.id)) {
						// tslint:disable-next-line:no-eval
						eval(`${adModel.functionName}('${adModel.id}')`);
					} else {
						Vue.$log.debug(`Ignoring refresh of outstream-tag: no div with id='${adModel.id}' on current page`);
					}
				} else {
					w.gbucket.refresh(adModel.id);
				}
				Vue.$log.debug(`refreshed ad '${adModel.id}'`);
			} catch (e) {
				Vue.$log.error(`ad-slot '${adModel.id}' failed: '${e}'`);
			}
		});
	}
}
