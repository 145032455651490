import {LOCALE} from '@/utils/common.util';

const STATUS_UTIL = {
	toMinutes0: function (s: any): any {
		return this.toMinutesZeros(s, true);
	},
	toMinutes: function (s: any): any {
		return this.toMinutesZeros(s, false);
	},
	toMinutesZeros: function (s: any, fillZeros: boolean): any {
		if (s === undefined || s === null) {
			return '-';
		}
		if (!s && s !== 0) {
			return '-';
		}
		s = this.round(s, 0);
		const m = (s - (s %= 60)) / 60;
		let mStr = m + '';
		if (fillZeros) {
			if (m < 10) {
				mStr = `0${m}`;
			}
		}
		return mStr + (s >= 10 ? ':' : ':0') + s;
	},
	numberBig: function (value: any): any {
		const res: any = this.round(value, 0);
		return new Intl.NumberFormat(LOCALE).format(res);
	},
	round: function (value: any, digits: any): any {
		if (value === undefined || value === null) {
			return '';
		}
		if (!digits && digits !== 0) {
			digits = 1;
		}
		if ((!value && value !== 0) || Number.isNaN(value)) {
			return '-';
		}
		const factor = Math.pow(10, Math.max(0, digits));
		const result = Math.round(value * factor) / factor;
		return result.toFixed(digits);
	},
	percent: function (value: any, digits: any): any {
		if (value === undefined || value === null) {
			return '';
		}
		if (value === 0) {
			return '0%';
		}
		if ((!value && value !== 0) || Number.isNaN(value)) {
			return '-';
		}
		if (!digits && digits !== 0) {
			digits = 1;
		}
		return `${this.round(100 * value, digits)}%`;
	},
};

export default STATUS_UTIL;
