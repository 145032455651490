import Vue from 'vue';
import {HttpResponse} from 'vue-resource/types/vue_resource';
import BaseService from '@/services/base.service';
import {Roster} from '@/model/core/roster.model';


export class RosterService extends BaseService {

	rosterForTeam(teamId: string, seasonId?: string): Promise<Roster> {
		if (!teamId) {
			return Promise.reject('a team-id is required');
		}
		const params = new URLSearchParams();
		if (seasonId) {
			params.append('seasonId', seasonId);
		}
		const basePath = 'roster/team';
		const url = this.url(`${basePath}/${teamId}?${params}`);
		return Vue.http.get(url).then((resp: HttpResponse) => resp.data);
	}

}
