import Vue from 'vue';
import {Mixin} from 'vue-mixin-decorator';

const MOBILE_DETECT = require('mobile-detect');

@Mixin
export default class MobileMixin extends Vue {

	// keep lib-version, this implementation and constants in sync with index.html

	readonly MOBILE_DETECTION_DISABLED = false;
	readonly MAX_MOBILE_WIDTH_PX = 768;

	mobileDetect: any;

	created() {
		this.mobileDetect = new MOBILE_DETECT(window.navigator.userAgent);
	}

	isDesktop() {
		if (this.MOBILE_DETECTION_DISABLED) {
			return true;
		}
		return !this.isTablet() && !this.isMobile();
	}

	isTablet() {
		if (this.MOBILE_DETECTION_DISABLED) {
			return false;
		}
		return this.mobileDetect.tablet();
	}

	isMobile() {
		if (this.MOBILE_DETECTION_DISABLED) {
			return false;
		}
		return this.mobileDetect.mobile() || this.mobileDetect.isPhoneSized(this.MAX_MOBILE_WIDTH_PX);
	}

}
