













import {Component, Prop, Vue} from 'vue-property-decorator';
import {NavigationItem} from '@/model/site/navigation.model';

@Component({
  components: {},
})
export default class NavigationItemContent extends Vue {
  @Prop({type: Object, required: true}) item!: NavigationItem;
  @Prop({type: String, required: true}) id!: string;

}
