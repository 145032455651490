











































































import {Component, Vue} from 'vue-property-decorator';
import TEAM_SERVICE from '@/services/team.service';
import SEASON_SERVICE from '@/services/season.service';
import {IndexedTeam} from '@/model/site/state.model';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';
import {hasKeys} from '@/utils/common.util';
import {DO_REMOVE_FAVOURITE_TEAM_ID, DO_SET_FAVOURITE_TEAM_ID} from '@/store/user/mutations';
import {Getter} from 'vuex-class';
import {GET_FAVOURITE_TEAM_ID} from '@/store/user/getters';
import { Prop } from 'vue-property-decorator';
import StringFilters from '@/mixins/string-filters.mixin';

@Component({
  components: {HfTeamLogo},
  mixins: [StringFilters]
})
export default class FavouriteTeamSelection extends Vue {

  @Prop({type: String, default: 'button is-dark'}) triggerClasses?: string;
  @Prop({type: Boolean, default: true}) showLogo!: boolean;
  @Getter(GET_FAVOURITE_TEAM_ID) userFavouriteTeamId?: string;

  teamsA: IndexedTeam = {};
  teamsB: IndexedTeam = {};
  teamId = '';

  created() {
    this.teamId = this.favouriteTeamId || '';
    const seasonId = SEASON_SERVICE.currentId();
    TEAM_SERVICE.teams(seasonId, 'a').then((teams: IndexedTeam) => {
      this.teamsA = teams;
    });
    TEAM_SERVICE.teams(seasonId, 'b').then((teams: IndexedTeam) => {
      this.teamsB = teams;
    });
  }

  get show() {
    return hasKeys(this.teamsA) && hasKeys(this.teamsB);
  }

  get favouriteTeamId(): string | undefined {
    return this.userFavouriteTeamId;
  }

  set favouriteTeamId(teamId: string | undefined) {
    this.teamId = teamId || '';
    if (teamId) {
      this.$store.commit(DO_SET_FAVOURITE_TEAM_ID, teamId);
      // FIXME Toast is not displayed
      this.$buefy.toast.open({
        message: `Dein Lieblingsteam wurde aktualisiert`,
        position: 'is-top',
        duration: 5000,
        type: 'is-info',
      });
    } else {
      this.$store.commit(DO_REMOVE_FAVOURITE_TEAM_ID);
    }
  }

  get isFavouriteTeamIdSelected() {
    return this.favouriteTeamId !== undefined;
  }
}
