import {GameService} from '@/services/game.service';
import {CLEAR_GAMES_AT_DATE, SET_GAMES_AT_DATE, SET_LASTGAMES, SET_NEXTGAMES, SET_PLAYOFF, SET_RANKING} from '@/store/data/mutations';
import dayjs from 'dayjs';
import {RankingService} from '@/services/ranking.service';
import {RankingId} from '@/model/ranking.model';
import {wait} from '@/main';
import {PlayoffService} from '@/services/playoff.service';
import Vue from 'vue';
import {DATE_FORMAT_ISO} from '@/utils/date.util';
import {SET_FOCUS_DATE} from '@/store/context.store';

const gameService = new GameService();
const rankingService = new RankingService();
const playoffService = new PlayoffService();

export const LOAD_LASTGAMES = 'LOAD_LASTGAMES';
export const LOAD_NEXTGAMES = 'LOAD_NEXTGAMES';
export const LOAD_CURRENT_GAMES = 'LOAD_CURRENT_GAMES';
export const LOAD_GAMES_AT_DATE = 'LOAD_GAMES_AT_DATE';
export const LOAD_RANKING = 'LOAD_RANKING'; // rankings conists of both classic ranking and playoff-series

const ACTIONS = {
	LOAD_LASTGAMES(store: any): void {
		const lastgames = store.state.lastgames;
		if (Object.keys(lastgames).length === 0) {
			gameService.last()
				.then((data: any) => {
					store.commit(SET_LASTGAMES, data);
					store.commit(CLEAR_GAMES_AT_DATE, {});
				})
				.catch((error: any) => Vue.$log.error(`Error in ${LOAD_LASTGAMES}`, error));
		} else {
			store.commit(CLEAR_GAMES_AT_DATE, {});
		}
		store.commit(SET_FOCUS_DATE, new Date());
	},
	LOAD_NEXTGAMES(store: any): void {
		const nextgames = store.state.nextgames;
		if (Object.keys(nextgames).length === 0) {
			gameService.next()
				.then((data: any) => {
					store.commit(SET_NEXTGAMES, data);
					store.commit(CLEAR_GAMES_AT_DATE, {});
				})
				.catch((error: any) => Vue.$log.error(`Error in ${LOAD_NEXTGAMES}`, error));
		} else {
			store.commit(CLEAR_GAMES_AT_DATE, {});
		}
		store.commit(SET_FOCUS_DATE, new Date());
	},
	LOAD_CURRENT_GAMES(store: any): void {
		gameService.current()
			.then((data: any) => {
				store.commit(SET_NEXTGAMES, data['next']);
				store.commit(SET_LASTGAMES, data['last']);
				store.commit(CLEAR_GAMES_AT_DATE, {});
			})
			.catch((error: any) => Vue.$log.error(`Error in ${LOAD_NEXTGAMES}`, error));
		store.commit(SET_FOCUS_DATE, new Date());
	},
	LOAD_GAMES_AT_DATE(store: any, dateObj: Date): void {
		const dateStr = dayjs(dateObj).format(DATE_FORMAT_ISO);
		if (!store.state.gamesAtDateCache || !store.state.gamesAtDateCache[dateStr]) {
			gameService.gamesAt(dateObj)
				.then((data: any) => store.commit(SET_GAMES_AT_DATE, {dateStr, data}))
				.catch((error: any) => Vue.$log.error(`Error in ${LOAD_GAMES_AT_DATE}`, error));
		} else {
			store.commit(SET_GAMES_AT_DATE, {dateStr, data: store.state.gamesAtDateCache[dateStr]});
		}
		store.commit(SET_FOCUS_DATE, dateObj);
	},
	LOAD_RANKING(store: any, rankingId: RankingId): void {
		if (!store.state.rankingCache || !store.state.rankingCache[rankingId.key]) {
			wait.start(LOAD_RANKING);
			const ranking = rankingService.rankingAt(rankingId);
			const playoff = playoffService.playoffSeriesAt(rankingId.leagueId, rankingId.date);
			Promise.all([ranking, playoff])
				.then((data: any) => {
					store.commit(SET_RANKING, {rankingId, ranking: data[0]});
					store.commit(SET_PLAYOFF, {rankingId, playoff: data[1]});
				})
				.catch((error: any) => Vue.$log.error(`Error in ${LOAD_RANKING}`, error))
				.finally(() => wait.end(LOAD_RANKING));
		} else {
			store.commit(SET_RANKING, {rankingId, ranking: store.state.rankingCache[rankingId.key]});
			store.commit(SET_PLAYOFF, {rankingId, playoff: store.state.playoffCache[rankingId.key]});
		}
	},
};

export default ACTIONS;
