





















import {Component, Vue} from 'vue-property-decorator';
import {WARNINGS} from '@/store/context.store';
import {Getter} from 'vuex-class';

@Component({
  components: {},
})
export default class ErrorDisplay extends Vue {

  @Getter(WARNINGS) warnings!: string[];

  get warningCount() {
    return this.warnings ? this.warnings.length : 0;
  }

  get hasWarnings() {
    return this.warningCount > 0;
  }

  get tooltipText() {
    if (this.hasWarnings) {
      return 'Es gab Fehler beim Laden der Seite';
    } else {
      return '';
    }
  }

}
