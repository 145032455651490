import { UserData } from '.';

const FAVOURITE_TEAM_ID = 'favouriteTeamId';
const SHOW_ADS = 'showAds';
const DEVELOPER_MODE = 'developerMode';

export const GET_FAVOURITE_TEAM_ID = `user/${FAVOURITE_TEAM_ID}`;
export const GET_SHOW_ADS = `user/${SHOW_ADS}`;
export const GET_DEVELOPER_MODE = `user/${DEVELOPER_MODE}`;

const GETTERS = {
	[FAVOURITE_TEAM_ID]: (data: UserData) => data.favouriteTeamId,
	[SHOW_ADS]: (data: UserData) => data.showAds,
	[DEVELOPER_MODE]: (data: UserData) => data.developerMode
};

export default GETTERS;
