import 'vue-class-component/hooks';
import Vue from 'vue';
import App from './App.vue';
import VueLogger from 'vuejs-logger';
import VueI18n from 'vue-i18n';
import messages_de from '@/messages/messages_de';
import router, {ROUTING_MODE} from '@/router';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import {LOCALE} from '@/utils/common.util';
import store from '@/store/index.store';
import VueAnalytics from 'vue-analytics';
import VueResource from 'vue-resource';
import VueWait from 'vue-wait';
import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import {ADD_WARNING} from '@/store/context.store';
import Meta from 'vue-meta';
import LoadScript from 'vue-plugin-load-script';
import browserDetect from 'vue-browser-detect-plugin';
import {BASE_URL} from '@/services/base.service';

// Polyfills
require('url-search-params-polyfill');
require('es6-promise').polyfill();

// config
const LOG_CONFIGURATION_VALUES = false;
const IS_PROD = (process.env.NODE_ENV === 'production');
const LOG_LEVEL = (IS_PROD ? 'info' : 'debug');
const GOOGLE_ANALYTICS_DEBUG = false;
const REPORT_ERRORS = IS_PROD;
const HTTP_REQUEST_TIMEOUT_MS = 20000;

Vue.config.productionTip = false;

// Logger
const options = {
	logLevel: LOG_LEVEL,
	showMethodName: true,
	showConsoleColors: true,
	showLogLevel : true,
	stringifyArguments: false,
	separator: '>'
};
Vue.use(VueLogger, options);
Vue.config.errorHandler = function (err: Error, vm: Vue, info: string): void {
	const vue: any = Vue;
	vue.$log.error(`error: "${err}" in [${info}], Vue: `, vm);
};
Vue.config.warnHandler = function (msg: string, vm: Vue, trace: string): void {
	const vue: any = Vue;
	vue.$log.warn(`warning: "${msg}" in [${trace}], Vue: `, vm);
};

// disabled service-worker (i.e. CORS error)
// import './registerServiceWorker';

Vue.prototype.$appName = process.env.NAME;
Vue.prototype.$appVersion = process.env.VERSION;

function environment() {
	if (window && window.location && window.location.hostname) {
		return window.location.hostname;
	}
	return process.env.NODE_ENV;
}

// report errors
if (REPORT_ERRORS) {
	Sentry.init({
		dsn: 'https://92501cb01a804d4dbb4b75466a1e4a90@sentry.io/1437712',
		release: `${process.env.NAME}@${process.env.VERSION}`,
		environment: environment(),
		integrations: [
			new Integrations.Vue({
				Vue,
				attachProps: false,
			}),
		],
	});
}

// script tag
Vue.use(require('vue-script2'));

// Buefy / Bulma: import individually to safe bundle size
// Update: Fehler im Dropdown, mit globalem Import klappts
// further improvements would be to load the async
// import Buefy from 'buefy';
// Vue.use(Buefy);
// @ts-ignore
import { Button, Dropdown, Navbar, Message, Icon, Switch, Tabs, Table, Toast, Tooltip } from 'buefy';
Vue.use(Button);
Vue.use(Dropdown);
Vue.use(Icon);
Vue.use(Navbar);
Vue.use(Message);
Vue.use(Switch);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Toast);
Vue.use(Tooltip);

// VueResource
Vue.use(VueResource);
// Vue.http.headers.common['Access-Control-Allow-Origin'] = '*';
if (process.env.NODE_ENV === 'production') {
	Vue.http.options.timeout = HTTP_REQUEST_TIMEOUT_MS;
}
Vue.http.interceptors.push((request: any, next: any) => {
	Vue.$log.debug(`request to ${request.url}`, request);
	next((response: any) => {
		if (!response.ok) {
			response.statusText = 'Error getting data';
			const reason = response.status ? response.status : 'Timeout';
			const errorMsg = `Error getting data '${request.url}': ${reason}`;
			Vue.$log.error(errorMsg, request.params);
			if (REPORT_ERRORS) {
				Sentry.captureMessage(errorMsg, Severity.Error);
			}
			store.commit(ADD_WARNING, `Fehler beim Laden von ${request.url}: ${reason}`);
		}
		return response;
	});
});

// various plugins
Vue.use(LoadScript); // TODO is this really needed
Vue.use(VueI18n);
Vue.use(VueWait);
Vue.use(Meta);
Vue.use(browserDetect);

// i18n
dayjs.locale(LOCALE);
const i18n = new VueI18n(<any>{
	locale: LOCALE,
	messages: {
		de: messages_de,
	},
});

// Log configuration
if (LOG_CONFIGURATION_VALUES) {
	Vue.$log.debug('Version', process.env.VERSION);
	Vue.$log.debug('Name', process.env.NAME);
	Vue.$log.debug('Production', IS_PROD);
	Vue.$log.debug('RoutingMode', ROUTING_MODE);
	Vue.$log.debug('Locale', LOCALE);
	Vue.$log.debug('API-Root', BASE_URL);
	Vue.$log.debug('HTTP-Request Timeout [ms]', HTTP_REQUEST_TIMEOUT_MS);
}

// Google Analytics
Vue.use(VueAnalytics, {
	id: 'UA-654339-5',
	router,
	debug: {
		enabled: !IS_PROD && GOOGLE_ANALYTICS_DEBUG,
		sendHitTask: IS_PROD,
	},
});

// Vue Wait
export const wait = new VueWait({
	useVuex: true,
});

// Custom mixins
Vue.mixin({
	methods: {
		$logDebug: function (param: any) {
			Vue.$log.debug('debug', param);
		}
	}
});

// glue everything together
new Vue({
    router,
    store,
    i18n,
    render: (h) => h(<any>App),

    // @ts-ignore: may be needed, but as v1.3.3 is broken, wait for v1.3.4
    wait: wait
}).$mount('#app');
