import MobileMixin from '@/mixins/mobile.mixin';

export const AD_FORMAT_KEYS = {
	rectangle: 'rectangle',
	skyscraper: 'skyscraper',
	leaderboard: 'leaderboard',
	rectangleMobile: 'rectangleMobile',
	leaderboardMobile: 'leaderboardMobile',
	videoheadMobile: 'videoheadMobile',
	rectangleTablet: 'rectangleTablet',
	leaderboardTablet: 'leaderboardTablet',
	leaderboardBig: 'leaderboardBig',
	outstreamMobile: 'outstreamMobile',
	outstreamTablet: 'outstreamTablet',
	outstreamDesktop: 'outstreamDesktop',
};

export type AdTarget = 'mobile' | 'tablet' | 'desktop';

export interface AdModel {
	id: string;
	functionName: string;
	adSlot?: string;
	style?: string;
	target?: AdTarget;
	outstream?: boolean;
}

interface AdFormats {
	[format: string]: AdModel;
}

// see Google_Tags_HF.txt
export const AD_FORMATS: AdFormats = {
	// Desktop: 300x250
	// - div-ad-gds-2403-4: 960x800 -> TODO [ad] define adSlot
	[AD_FORMAT_KEYS.rectangle]: {
		id: 'div-ad-gds-2403-1',
		functionName: 'gbcallslot2403',
		adSlot: '7349400424',
		style: 'width:300px;height:250px',
		target: 'desktop'
	},
	// .. skyscraper: 300x600,160x600, 500x1000
	[AD_FORMAT_KEYS.skyscraper]: {
		id: 'div-ad-gds-2403-2',
		functionName: 'gbcallslot2403',
		adSlot: '7508302442',
		style: 'width:160px;height:600px',
		target: 'desktop'
	},
	// .. leaderboard: 994x500,994x250, 728x90
	[AD_FORMAT_KEYS.leaderboard]: {
		id: 'div-ad-gds-2403-3',
		functionName: 'gbcallslot2403',
		adSlot: '8376020562',
		style: 'width:994px;height:250px',
		target: 'desktop'
	},

	// Tablet: 300x250
	[AD_FORMAT_KEYS.rectangleTablet]: {
		id: 'div-ad-gds-2404-1',
		functionName: 'gbcallslot2404',
		adSlot: '7349400424',
		style: 'width:300px;height:250px',
		target: 'tablet'
	},
	// .. leaderboard: 994x250, 728x90
	[AD_FORMAT_KEYS.leaderboardTablet]: {
		id: 'div-ad-gds-2404-2',
		functionName: 'gbcallslot2404',
		adSlot: '8475516461',
		style: 'width:728px;height:90px',
		target: 'tablet'
	},
	// .. leaderboard big: 768x1024
	[AD_FORMAT_KEYS.leaderboardBig]: {
		id: 'div-ad-gds-2404-3',
		functionName: 'gbcallslot2404',
		target: 'tablet'
	},

	// Mobile: 300x250,320x416
	// - div-ad-gds-2402-4: 828x910 -> TODO [ad] makes no sense on mobile / already included in 2402-3
	[AD_FORMAT_KEYS.rectangleMobile]: {
		id: 'div-ad-gds-2402-1',
		functionName: 'gbcallslot2402',
		adSlot: '7349400424',
		style: 'width:300px;height:250px',
		target: 'mobile'
	},
	// .. leaderboard: 320x160,320x50,74x500,300x200
	[AD_FORMAT_KEYS.leaderboardMobile]: {
		id: 'div-ad-gds-2402-2',
		functionName: 'gbcallslot2402',
		adSlot: '2221675752',
		style: 'width:300px;height:50px',
		target: 'mobile'
	},
	// videohead / sitebar: 300x250, 320x416, 300x200, 828x910
	[AD_FORMAT_KEYS.videoheadMobile]: {
		id: 'div-ad-gds-2402-3',
		functionName: 'gbcallslot2402',
		adSlot: '2452296963',
		style: 'width:300px;height:200px',
		target: 'mobile'
	},
	// Outstream for all devices - TODO [ad] but only available from Goldbach for desktop
	[AD_FORMAT_KEYS.outstreamDesktop]: {
		id: 'div-ad-gds-361-1',
		functionName: 'gbcallslot361',
		target: 'desktop',
		outstream: true
	},
	[AD_FORMAT_KEYS.outstreamMobile]: {
		id: 'div-ad-gds-362-1',
		functionName: 'gbcallslot362',
		target: 'mobile',
		outstream: true
	},
	[AD_FORMAT_KEYS.outstreamTablet]: {
		id: 'div-ad-gds-363-1',
		functionName: 'gbcallslot363',
		target: 'tablet',
		outstream: true
	}
};

function filterAdFormat(target: AdTarget): AdModel[] {
	return Object.keys(AD_FORMATS).filter(key => AD_FORMATS[key].target === target).map(key => AD_FORMATS[key]);
}

let adFormats: AdModel[] = [];

const mobileMixin = new MobileMixin();
if (mobileMixin.isDesktop()) {
	adFormats = filterAdFormat('desktop');
} else if (mobileMixin.isMobile()) {
	adFormats = filterAdFormat('mobile');
} else if (mobileMixin.isTablet()) {
	adFormats = filterAdFormat('tablet');
}

export const AD_FORMATS_FOR_DEVICE: AdModel[] = adFormats;
