import {GameDetail, GameInfo} from '@/model/game/game.model';
import BaseService from '@/services/base.service';
import {GamePicture} from '@/model/site/picture.model';
import Vue from 'vue';
import {HttpResponse} from 'vue-resource/types/vue_resource';
import {GameArticle} from '@/model/article/article.model';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/browser';


export class GameDetailService extends BaseService {

	private article(gameInfo: GameInfo): Promise<GameArticle> {
		const params = new URLSearchParams();
		params.append('dateStr', dayjs(gameInfo.timestamp * 1000).format('YYMMDD'));
		params.append('homeTeamId', gameInfo.homeTeamId);
		params.append('awayTeamId', gameInfo.awayTeamId);
		const url = this.url(`game/article/${gameInfo.gameId}?${params}`);
		return Vue.http.get(url)
			.then((r: HttpResponse) => r.data)
			.catch((error: any) => {
				if (error.status !== 404) {
					Promise.reject(`Fehler beim Laden des Berichts zum Spiel ${gameInfo.gameId}`);
				}
			});
	}

	public info(gameId: string): Promise<GameInfo> {
		const url = this.url(`game/info/${gameId}`);
		return Vue.http.get(url)
			.then((r: HttpResponse) => {
				if (r.status === 204 || !r.data) {
					return Promise.reject(`Keine Informationen zum Spiel "${gameId}" vorhanden`);
				}
				return r.data;
			})
			.catch((error: any) => {
				if (error.status === 404) {
					return Promise.reject(`Das Spiel "${gameId}" existiert nicht`);
				}
			});
	}

	private data(gameId: string): Promise<any> {
		const url = this.url(`game/data/${gameId}`);
		return Vue.http.get(url).then((r: HttpResponse) => r.data);
	}

	public pictures(gameInfo: GameInfo): Promise<GamePicture[]> {
		const url = this.url(`game/pictures/${gameInfo.galleryId}`);
		return Vue.http.get(url)
			.then((r: HttpResponse) => r.data)
			.catch((error: any) => {
				Sentry.captureMessage(`Error fetching pictures for ${gameInfo.gameId} - ${gameInfo.galleryId} - ${error.status}`, Severity.Warning);
			});
	}

	public detail(gameInfo: GameInfo): Promise<GameDetail> {
		const gameId = gameInfo.id;
		const article: any = gameInfo.hasArticle ? this.article(gameInfo) : Promise.resolve();
		const data: any = gameInfo.hasData ? this.data(gameId) : Promise.resolve();
		const pictures: any = gameInfo.hasPictures ? this.pictures(gameInfo) : Promise.resolve();
		return Promise.all([article, data, pictures])
			.then((data: any) => {
				return {
					info: gameInfo,
					article: data[0],
					data: data[1],
					pictures: data[2],
				};
			});
	}
}

