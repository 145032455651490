var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{attrs:{"fixed-top":"","type":"is-dark","wrapper-class":"hf-vertical-wrapper","mobile-burger":false}},[_c('template',{slot:"brand"},[(!_vm.standaloneStatsMode)?_c('b-navbar-item',{attrs:{"tag":"router-link","to":{ path: '/' }}},[_c('img',{attrs:{"src":require("@/assets/image/logo-flag.png"),"alt":"Hockeyfans.ch"}})]):_vm._e(),(!_vm.standaloneStatsMode && _vm.showOnMobileScreen)?_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('FavouriteTeamSelection')],1):_vm._e(),(_vm.developerMode)?_c('BreakpointInfo'):_vm._e(),(_vm.showOnMobileScreen)?_c('Slide',{attrs:{"right":"","is-open":_vm.open,"disable-outside-click":""},on:{"openMenu":function($event){return _vm.handleOpenMenu()},"closeMenu":function($event){return _vm.handleCloseMenu()}}},[_c('BulmaAccordion',{attrs:{"icon":'caret',"caret-animation":{
          duration: '0.1s',
          timerFunc: 'ease-in-out'
        },"slide":{
          duration: '0.1s',
          timerFunc: 'ease'
        }}},[_vm._l((_vm.navigationItems),function(item,i){return [(_vm.hasSubitems(item))?_c('BulmaAccordionItem',{key:i},[_c('template',{slot:"title"},[(item.target)?[_c('router-link',{attrs:{"to":_vm.target(item)},nativeOn:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(item.text)+" ")])]:[_vm._v(" "+_vm._s(item.text)+" ")]],2),_c('template',{slot:"content"},[_c('ul',[_vm._l((_vm.subitems(item)),function(subitem,j){return [(subitem.separator)?_c('div',{key:j,staticClass:"hf-nav-separator"}):(_vm.target(item, subitem))?_c('li',{key:j},[(_vm.target(item, subitem))?_c('router-link',{attrs:{"to":_vm.target(item, subitem)},nativeOn:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(subitem.text)+" ")]):_vm._e()],1):_vm._e()]})],2)])],2):_c('div',{key:i,staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-title"},[_c('router-link',{attrs:{"to":_vm.target(item)},nativeOn:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(item.text)+" ")])],1)])])]})],2)],1):_vm._e()],1),_c('template',{slot:"start"},[(!_vm.showOnMobileScreen)?[_vm._l((_vm.navigationItems),function(item){return [(_vm.hasSubitems(item))?_c('b-navbar-dropdown',{key:item.target,class:{
            'router-link-active': _vm.hasActiveItem(item),
            'has-icons': !_vm.standaloneStatsMode},attrs:{"hoverable":""}},[_c('template',{slot:"label"},[(item.target)?_c('b-navbar-item',{key:item.target,staticClass:"hf-navbar-main-item",class:{'has-icons': !_vm.standaloneStatsMode},attrs:{"href":"#","tag":"router-link","to":_vm.target(item)}},[_c('NavigationItemContent',{attrs:{"item":item,"id":_vm.itemId(item)}})],1):_c('NavigationItemContent',{staticClass:"hf-navbar-main-item",attrs:{"item":item,"id":_vm.itemId(item)}})],1),_vm._l((item.subitems),function(subitem){return [_c('div',{key:subitem.target,attrs:{"id":_vm.itemId(subitem)}},[(subitem.separator)?_c('div',{staticClass:"hf-nav-separator"}):(_vm.target(item, subitem))?_c('b-navbar-item',{class:{'has-icons': !_vm.standaloneStatsMode},attrs:{"href":"#","tag":"router-link","to":_vm.target(item, subitem)}},[_vm._v(" "+_vm._s(subitem.text)+" ")]):_vm._e()],1)]})],2):_c('b-navbar-item',{key:item.target,class:{'has-icons': !_vm.standaloneStatsMode},attrs:{"id":_vm.itemId(item),"href":"#","tag":"router-link","to":_vm.target(item)}},[_c('NavigationItemContent',{attrs:{"item":item,"id":_vm.itemId(item)}})],1)]})]:_vm._e()],2),_c('template',{slot:"end"},[(!_vm.standaloneStatsMode && !_vm.showOnMobileScreen)?_c('b-navbar-item',{staticClass:"is-gapless",attrs:{"tag":"div"}},[_c('FavouriteTeamSelection')],1):_vm._e(),(_vm.developerMode)?_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('DevelLink')],1):_vm._e(),(_vm.standaloneStatsMode)?_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('VersionInfo')],1):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }