import {GameTypeId, LeagueId} from '@/model/core/core-data.model';
import {Context} from '@/model/site/context.model';
import dayjs from 'dayjs';

export const DEFAULT_LEAGUE_ID: LeagueId = 'a';
export const DEFAULT_SEASON_ID: string = '2122';
export const DEFAULT_GAME_TYPE_ID: GameTypeId = 'm';
export const EMPTY_GROUP_ID = '';
export const ALL_GROUP_ID = 'all';
const defaultContext: Context = {
	leagueId: DEFAULT_LEAGUE_ID,
	groupId: EMPTY_GROUP_ID,
	configuration: {},
	focusDate: new Date(),
	focusSeasonId: DEFAULT_SEASON_ID,
	focusLeagueId: DEFAULT_LEAGUE_ID,
	focusGameTypeId: DEFAULT_GAME_TYPE_ID,
	focusTeamId: undefined,
	favouriteTeamId: undefined,
	refreshable: false,
	warnings: [],
};
const context = defaultContext;

// const
// - getters
export const CONTEXT = 'context';
export const SELECTED_LEAGUE_ID = 'selectedLeagueId';
export const SELECTED_GROUP_ID = 'selectedGroupId';
export const INDEX_CONFIG = 'index';
export const NEWS_CONFIG = 'news';
export const GAME_CONFIG = 'game';
export const EVENT_CONFIG = 'event';
export const SITE_CONFIG = 'site';
export const FORUM_CONFIG = 'forum';
export const MATCHDAY_CONFIG = 'matchday';
export const FOCUS_DATE = 'focusDate';
export const FOCUS_SEASON_ID = 'focusSeasonId';
export const FOCUS_LEAGUE_ID = 'focusLeagueId';
export const FOCUS_GAME_TYPE_ID = 'focusGameTypeId';
export const FOCUS_TEAM_ID = 'focusTeamId';
export const WARNINGS = 'warnings';
export const REFRESHABLE = 'refreshable';

// - mutations
export const SELECT_LEAGUE_ID: any = 'selectLeagueId';
export const SELECT_GROUP_ID: any = 'selectGroupId';
export const SET_FOCUS_DATE: any = 'setFocusDate';
export const SET_FOCUS_LEAGUE_ID: any = 'setFocusLeagueId';
export const SET_FOCUS_SEASON_ID: any = 'setFocusSeasonId';
export const SET_FOCUS_GAME_TYPE_ID: any = 'setFocusGameTypeId';
export const SET_FOCUS_TEAM_ID: any = 'setFocusTeamId';
export const REMOVE_FOCUS_TEAM_ID: any = 'removeFocusTeamId';
export const SET_CONFIGURATION: any = 'setConfiguration';
export const ADD_WARNING: any = 'addWarning';
export const RESET_WARNINGS: any = 'resetWarnings';
export const DISABLE_REFRESHABLE: any = 'disableRefreshable';
export const ENABLE_REFRESHABLE: any = 'enableRefreshable';

// getters
const getters = {
	[CONTEXT]: (context: Context) => context,
	[SELECTED_LEAGUE_ID]: (context: Context) => context.leagueId,
	[SELECTED_GROUP_ID]: (context: Context) => context.groupId,
	[INDEX_CONFIG]: (context: Context) => context.configuration.index,
	[NEWS_CONFIG]: (context: Context) => context.configuration.news,
	[SITE_CONFIG]: (context: Context) => context.configuration.site,
	[FORUM_CONFIG]: (context: Context) => context.configuration.forum,
	[MATCHDAY_CONFIG]: (context: Context) => context.configuration.matchday,
	[GAME_CONFIG]: (context: Context) => context.configuration.game,
	[EVENT_CONFIG]: (context: Context) => context.configuration.event,
	[FOCUS_DATE]: (context: Context) => context.focusDate ? new Date(context.focusDate) : new Date(),
	[FOCUS_LEAGUE_ID]: (context: Context) => context.focusLeagueId || DEFAULT_LEAGUE_ID,
	[FOCUS_SEASON_ID]: (context: Context) => context.focusSeasonId || DEFAULT_SEASON_ID,
	[FOCUS_GAME_TYPE_ID]: (context: Context) => context.focusGameTypeId || DEFAULT_GAME_TYPE_ID,
	[FOCUS_TEAM_ID]: (context: Context) => context.focusTeamId,
	[WARNINGS]: (context: Context) => context.warnings,
	[REFRESHABLE]: (context: Context) => context.refreshable,
};

// mutations
const mutations = {
	[SELECT_LEAGUE_ID](context: Context, value: LeagueId): void {
		context.groupId = EMPTY_GROUP_ID;
		context.leagueId = value || DEFAULT_LEAGUE_ID;
	},
	[SELECT_GROUP_ID](context: Context, value: string): void {
		context.groupId = value || EMPTY_GROUP_ID;
	},
	[SET_CONFIGURATION](context: Context, configuration: any): void {
		context.configuration = configuration;
	},
	[SET_FOCUS_DATE](context: Context, date: Date): void {
		if (dayjs(date).isValid()) {
			context.focusDate = date;
		}
	},
	[SET_FOCUS_LEAGUE_ID](context: Context, value: LeagueId): void {
		context.focusLeagueId = value || DEFAULT_LEAGUE_ID;
	},
	[SET_FOCUS_SEASON_ID](context: Context, value: string): void {
		context.focusSeasonId = value || DEFAULT_SEASON_ID;
	},
	[SET_FOCUS_GAME_TYPE_ID](context: Context, value: GameTypeId): void {
		context.focusGameTypeId = value || DEFAULT_GAME_TYPE_ID;
	},
	[SET_FOCUS_TEAM_ID](context: Context, value: string | undefined): void {
		context.focusTeamId = value;
	},
	[REMOVE_FOCUS_TEAM_ID](context: Context): void {
		context.focusTeamId = undefined;
	},
	[ADD_WARNING](context: Context, errorMsg: string): void {
		context.warnings.push(errorMsg);
	},
	[RESET_WARNINGS](context: Context): void {
		context.warnings = [];
	},
	[ENABLE_REFRESHABLE](context: Context): void {
		context.refreshable = true;
	},
	[DISABLE_REFRESHABLE](context: Context): void {
		context.refreshable = false;
	},
};

// actions
const actions = {};

const contextStore = {
	state: context,
	getters,
	actions,
	mutations,
};

// export
export default contextStore;
