import {GameArticle} from '@/model/article/article.model';
import {GamePicture} from '@/model/site/picture.model';
import STATUS_UTIL from '@/utils/stats.util';
import Player from '@/model/core/player.model';
import {
	EMPTY_FINAL_ROUND_ID,
	GameTypeId,
	getFinalRoundId,
	getGroupId,
	GROUP_RELEGATION,
	isExhibition,
	isRegularRoundGame,
	LeagueId
} from '@/model/core/core-data.model';
import {PlayoffSerieTO} from '@/model/ranking.model';
import {EMPTY_GROUP_ID} from '@/store/context.store';
import {GameContentType} from '@/model/site/components.model';

export interface IndexedGames {
	[key: string]: GameInfo[];
}

export interface IndexedGroups {
	[leagueId: string]: string[];
}

/**
 * Other than the deprecated properties are in fact depracated as well.
 */
export interface GameInfo {
	/** @deprecated: use gameId instead */
	id: string;
	gameId: string;
	homeTeamId: string;
	homeTeamName: string;
	homeTeamNameShort?: string;
	homeTeamCountryId?: string;
	awayTeamId: string;
	awayTeamName: string;
	awayTeamNameShort?: string;
	awayTeamCountryId?: string;
	/** @deprecated */
	logoPathHome: string;
	/** @deprecated */
	logoPathAway: string;
	placeText: string;
	attendance?: number;
	date: string;
	time: string;
	round: string;
	timestamp: number;
	leagueId: LeagueId;
	shortLeague?: string;
	gameTypeId: GameTypeId;
	title?: string;
	result: string;
	periods?: string;
	homeGoals: string;
	awayGoals: string;
	homePeriods: number[];
	awayPeriods: number[];
	scorersHome: string;
	scorersAway: string;
	articleTitle?: string;
	deeplink: string;
	hasArticle: boolean;
	hasPictures: boolean;
	mainPictureUrl?: string;
	galleryId?: number;
	hasData: boolean;
	playoffSerie?: PlayoffSerieTO;
}

export interface GameInfoWithRound extends GameInfo {
	roundInfo: string;
}

export function roundInfo(vm: any, game: GameInfo, short?: boolean): string {
	if (!game.round) {
		return '';
	}
	const groupId = getGroupId(game);
	const finalRoundId = getFinalRoundId(game);
	if (groupId !== EMPTY_GROUP_ID) {
		if (groupId === GROUP_RELEGATION) {
			return 'Relegation';
		}
		return `Gruppe ${groupId.toUpperCase()}`;
	} else if (finalRoundId !== EMPTY_FINAL_ROUND_ID) {
		const msgKey = short ? 'roundIdShort' : 'roundIdLong';
		return vm.$t(`${msgKey}.${finalRoundId}`);
	} else if (isRegularRoundGame(game)) {
		return `${game.round}. ST`;
	} else if (isExhibition(game)) {
		return 'Testspiel';
	} else {
		vm.$log.warn(`No round-info [${game.leagueId}-${game.gameTypeId}-${game.round}] for gameId=${game.gameId}`);
		return '';
	}
}

export interface GameDetail {
	info: GameInfo;
	article: GameArticle;
	data: any;
	pictures: GamePicture[];
}

export interface GameData {
	goals: any[];
	penaltys: any[];
	goalies: any[];
	gameAssignementStatsHome: any[];
	gameAssignementStatsAway: any[];
	gameGoalkeeperStatsHome: any[];
	gameGoalkeeperStatsAway: any[];
}

export interface GameEvent {
	eventType: GameEventType;
	timeDisplay: string;
	iconName: string;
	iconPack: string;
	mainInfo: string;
	secondaryInfo: string;
	result: string;
	resultSub: string;
	teamId?: string;
}

export enum GameEventType {
	ALL = 'all',
	GOAL = 'goal',
	PENALTY = 'penalty',
	GOALKEEPER = 'goalkeeper',
	PERIOD = 'period',
}

export class Penalty implements GameEvent {
	constructor(private data: any, private info?: GameInfo) {
	}
	eventType = GameEventType.PENALTY;
	iconPack = 'fas';
	iconName = 'bell';
	mainInfo = this.data.playerDisplay;
	result = `${this.data.penaltyMinutes}'`;
	get resultSub() {
		if (this.data.effectiveTime !== this.data.penaltyMinutes * 60) {
			return this.data.effectiveTimeDisplay;
		}
		return '';
	}
	secondaryInfo = this.data.penaltyTypeDisplay;
	timeDisplay = this.data.timeDisplay;
	teamId = this.info ? (this.data.ha === 'H' ? this.info.homeTeamId : this.info.awayTeamId) : undefined;
}

export class Goal implements GameEvent {
	constructor(private data: any, private info?: GameInfo) {
	}
	eventType = GameEventType.GOAL;
	iconPack = 'fas';
	iconName = 'hockey-puck';
	get mainInfo() {
		if (this.data.scorerId === Player.UNKNOWN && this.data.shootoutGwg) {
			return 'Penaltyschiessen';
		}
		return this.data.scorerDisplay;
	}
	result = `${this.data.hg}:${this.data.ag}`;
	get resultSub() {
		const res = [];
		if (this.data.goalType !== 'EQ') {
			res.push(this.data.goalType);
		}
		if (this.data.emptyNetter === 1) {
			res.push('EN');
		}
		return res.join('‧');
	}
	secondaryInfo = [this.data.assist1DisplayShort, this.data.assist2DisplayShort].filter((e: any) => e).join(', ');
	timeDisplay = this.data.timeDisplay;
	teamId = this.info ? (this.data.ha === 'H' ? this.info.homeTeamId : this.info.awayTeamId) : undefined;
}

export class GkIn implements GameEvent {
	constructor(private data: any, private info: GameInfo) {
	}
	eventType = GameEventType.GOALKEEPER;
	iconPack = 'fas';
	iconName = 'sign-in-alt';
	mainInfo = this.data.playerDisplay;
	result = '';
	resultSub = '';
	secondaryInfo = 'Torhüter rein';
	timeDisplay = STATUS_UTIL.toMinutes0(this.data.timeIn);
	teamId = this.info ? (this.data.ha === 'H' ? this.info.homeTeamId : this.info.awayTeamId) : undefined;
}

export class GkOut implements GameEvent {
	constructor(private data: any, private info: GameInfo) {
	}
	eventType = GameEventType.GOALKEEPER;
	iconPack = 'fas';
	iconName = 'sign-out-alt';
	mainInfo = this.data.playerDisplay;
	result = this.data.winLoss !== '-' ? this.data.winLoss : '';
	resultSub = STATUS_UTIL.toMinutes0(this.data.duration);
	secondaryInfo = 'Torhüter raus';
	timeDisplay = STATUS_UTIL.toMinutes0(this.data.timeOut);
	teamId = this.info ? (this.data.ha === 'H' ? this.info.homeTeamId : this.info.awayTeamId) : undefined;
}

export class Period implements GameEvent {
	constructor(private period: number, private title: string) {
	}
	eventType = GameEventType.PERIOD;
	iconName = '';
	iconPack = '';
	mainInfo = this.title;
	result =  '';
	resultSub = '';
	secondaryInfo = '';
	get timeDisplay() {
		const minutes = (this.period - 1) * 20;
		return `${minutes}:00`;
	}
}

export class GameResultTO {
	constructor (public home: number, public away: number) {
	}
}

export function sortGamesPicturesFirst(games: GameInfo[]): GameInfo[] {
	return games.sort((g1, g2) => {
		if (g1.hasPictures && !g2.hasPictures) {
			return -1;
		}
		if (!g1.hasPictures && g2.hasPictures) {
			return +1;
		}
		return g1.gameId.localeCompare(g2.gameId);
	});
}

export function gameContentType(game: GameInfo): GameContentType {
	if (game.hasArticle) {
		return GameContentType.BERICHT;
	}
	if (game.hasData) {
		return GameContentType.TELEGRAMM;
	}
	if (game.hasPictures) {
		return GameContentType.BILDER;
	}
	return GameContentType.UNDEFINED;
}
