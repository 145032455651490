<template>
  <div>
    <BurgerMenu v-bind="this.$attrs" @openMenu="openMenu" @closeMenu="closeMenu">
      <slot/>
    </BurgerMenu>
  </div>
</template>

<script>
    import BurgerMenu from '@/components/layout/navigation/burger/BurgerMenu';

    export default {
      name: 'Slide',
      components: {
        BurgerMenu
      },
      methods: {
        openMenu() {
          this.$emit('openMenu');
        },
        closeMenu() {
          this.$emit('closeMenu');
        }
      }
    };
</script>


