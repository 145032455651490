import Vue from 'vue';

export const BASE_URL = process.env.VUE_APP_API_ROOT;
const BASE_HF_URL = process.env.VUE_APP_API_ROOT_HF;

export default class BaseService {

	private mocked = false;
	// private mocked = true;

	constructor() {
	}

	private baseUrl(): string | undefined {
		return BASE_URL;
	}

	private hfBaseUrl(): string | undefined {
		return BASE_HF_URL;
	}

	private mockedBase(): string {
		return '/data/mocked';
	}

	protected isMocked(): boolean {
		return this.mocked || process.env.VUE_APP_SERVICE_MOCK === 'true';
	}

	mockId(id: number): number {
		if (this.isMocked()) {
			return id % 2 === 0 ? 42 : 13;
		}
		return id;
	}

	protected mock() {
		Vue.$log.warn('Using mocked data services');
		this.mocked = true;
	}

	protected live() {
		Vue.$log.warn('Using live data services');
		this.mocked = false;
	}

	protected addParam(params: URLSearchParams, config: any, name: string, defaultValue: string | number) {
		const value = config && config.hasOwnProperty(name) && config[name] ? config[name] : defaultValue;
		params.append(name, value);
	}

	/**
	 * Create an URL for the 'new' Java-based Rest API.
	 */
	protected url(resource: string): string {
		let baseUrl = this.baseUrl();
		let suffix = '';
		if (this.isMocked()) {
			baseUrl = this.mockedBase();
			suffix = '.json';
			resource = resource.split('?').slice(0, 1).join('');
		}
		return `${baseUrl}/${resource}${suffix}`;
	}

	/**
	 * Create an URL for the HF-PHP Rest API.
	 */
	protected hfUrl(resource: string, mocked?: boolean): string {
		const baseUrl = this.isMocked() || mocked ? this.mockedBase() : this.hfBaseUrl();
		return `${baseUrl}/${resource}.json`;
	}
}
