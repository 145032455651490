import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import {IndexedGames, IndexedGroups} from '@/model/game/game.model';
import {isGroupStage, LeagueId, validLeagueId} from '@/model/core/core-data.model';

export function extractLeagueIds(gamesInfo: IndexedGames): LeagueId[] {
	const validLeagueData = filterValidLeagues(gamesInfo);
	if (!validLeagueData) {
		return [];
	}
	return Object.keys(validLeagueData).map(key => key as LeagueId);
}

export function filterValidLeagues(gamesInfo: IndexedGames): IndexedGames {
	let object = {};
	if (gamesInfo) {
		Object.keys(gamesInfo)
			.filter(key => gamesInfo.hasOwnProperty(key))
			.filter(key => validLeagueId(key) !== undefined)
			.forEach(key => object = {...object, [key]: gamesInfo[key]});
	}
	return object;
}

export function extractGroupIds(gamesInfo: IndexedGames): IndexedGroups {
	if (!gamesInfo) {
		return {};
	}
	const result: IndexedGroups = {};
	Object.keys(gamesInfo).forEach(g => {
		result[g] = gamesInfo[g]
			.filter(game => isGroupStage(game))
			.map(game => game.round)
			.sort((g1, g2) => g1.localeCompare(g2));
		result[g] = [...new Set(result[g])];
	});
	return result;
}

export const state: any = {
	leagueIds: [],
	topgame: {},
	lastgames: {},
	nextgames: {},
	gamesAtDate: {},
	gamesAtDateCache: {},
	gamesCache: {},
	currentGameId: '',
	ranking: {},
	rankingCache: {},
	playoff: {},
	playoffCache: {},
	dateStr: '',
	headlinesAtDate: [],
	latestHeadlines: [],
	playerNews: [],
	teamNews: [],
	otherNews: [],
};

const store = {
	state,
	getters,
	actions,
	mutations,
};

export default store;
